import { DiscoverWondersOfTabukSummarPackage } from "./DiscoverWondersOfTabukSummarPackage";
import { DiscoverSpiritOfTheTabuk } from "./DiscoverSpiritOfTheTabuk";

export const tourData = [

  {
    id: 'gulmarg-adventure-escapes',
    name: "Gulmarg Adventure Escapes",
    // Duration: "3 days and 2 Nights",
    imageUrl: require("../tabuk/03 .webp"),
    description: `
    Gulmarg's Kashmir is turned into a winter wonderland with breathtaking views blanketed in new snow. This package is designed to completely immerse you in the enchanting ambiance of Gulmarg, with activities ranging from thrilling ski adventures on well-known slopes to serene gondola rides that give spectacular panoramic views of snow-capped mountains. Give yourself to the beauty of this quaint town, its welcoming residents, and the abundance of activities that make it the perfect winter getaway. Join us as we create unforgettable moments in Gulmarg's winter paradise.`,
    whatToExpect: `Upon your arrival, our representative will warmly greet you outside the Srinagar airport's arrival gate (once you collect your baggage). You'll then be transferred to the hotel for check-in. After leaving your bags, settle into your comfortable air-conditioned SUV. Throughout the journey, your attentive tour leader will provide insights into the scenic surroundings, ensuring an informative and enjoyable experience. As you embark on your tour, let the captivating tales of Kashmir unfold, enhancing your exploration of this mesmerizing region.`,
    highlights: [
      
      "Skiing Paradise: Gulmarg is renowned as one of the best skiing destinations in the world, attracting enthusiasts from across the globe. The region's powdery snow, long slopes, and modern infrastructure make it an ideal playground for both beginners and experienced skiers alike.",
      "Gondola Ride: The Gulmarg Gondola is one of the highest cable cars in the world and offers breathtaking panoramic views of the surrounding mountains. Visitors can take a ride on the Gondola to reach Kongdori and Apharwat Peak, where they can enjoy stunning vistas and indulge in various snow activities.",
     "Golfing Haven: Gulmarg boasts the highest green golf course in the world, set against the backdrop of snow-capped peaks and verdant meadows. Golf enthusiasts can tee off amidst the scenic beauty of the Himalayas, making it a truly unforgettable experience.",
      "Alpine Meadows: Beyond the snow-covered slopes, Gulmarg is dotted with picturesque alpine meadows adorned with colorful flowers during the summer months. Visitors can trek through these meadows, breathing in the fresh mountain air and reveling in the tranquility of nature.",
      "Adventure Sports: In addition to skiing and golfing, Gulmarg offers a variety of adventure sports activities, including snowboarding, trekking, mountain biking, and horse riding. The diverse terrain provides ample opportunities for adventure seekers to indulge in their favorite outdoor pursuits.",
      "Shrine of Baba Reshi: Located amidst serene surroundings, the Shrine of Baba Reshi is a sacred place revered by both locals and tourists. Dedicated to the Sufi saint Baba Reshi, the shrine is known for its intricate woodwork and architectural beauty, making it a must-visit spiritual destination.",
      "Local Cuisine: Visitors to Gulmarg can savor the delectable Kashmiri cuisine, characterized by rich flavors and aromatic spices. From traditional Wazwan dishes to street food delicacies, there's something to tantalize every taste bud in Gulmarg.",
      "Cultural Experiences: Gulmarg offers glimpses into the rich cultural heritage of Kashmir through its vibrant festivals, traditional music and dance performances, and handicraft markets. Visitors can immerse themselves in the local culture and interact with friendly locals to gain insights into their way of life.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [   
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Aldisah Valley Tour",
    //     description: [
    //       {
    //         time: "8:00 AM",
    //         title: "",
    //         detail:
    //           "Proceed to Aldissa Valley (Wadi Qaraqir) (Accompanied by a guide/driver).",
    //       },
    //       {
    //         time: "8:30 AM",
    //         title: "1st Stop:  Tabuk Castle.",
    //         detail:
    //           "Start you journey through at Tabuk castle. The castle in the center of the town is the oldest, it was built in 1559 during the reign of the Ottoman Empire. Check out the Tabuk Castle, the Hijaz Railway Station and take some photos and get back to your vehicles and proceed to Alshaq.",
    //       },
    //       {
    //         time: "9:00 AM",
    //         title: "2nd Stop:  The great Canyon Alshaq (Ledge of Neom).",
    //         detail:
    //           "Al-Shaq. This name means the tear (or rip) which makes sense as here the earthhas been literally torn apart by tectonic forces. That is the reason why this place is also known as the Great Canyon of Saudi Arabia, and it’s located in the Tabuk region between Jabal Hesma & Wade Qaraqir close to Shiqry village. Take a foot  walk and check out the ledge of NEOM. An overhang offers a perfect perspective for a souvenir picture. You can also check out the famous lion mountain as a mountain resembles the face of a lion. Finish the experience and get back inside the vehicle.",
    //       },
    //       {
    //         time: "10:30 AM",
    //         title: "Heads to Disah Valley.",
    //         detail:
    //           " (Disah is mainly located in the Prince Mohammed bin Salman Natural Reserve) in the Southwest Province of Tabuk.",
    //       },
    //       {
    //         time: "12:30 PM",
    //         title: "",
    //         detail:
    //           "Arrive in Disah Valley (The Valley of palms). Upon arrival, you’ll see  exactly why. The luscious valley floor is surrounded by massive sandstone cliffs  and pillars and beautiful lush palm trees that are perfect for exploration and  followed by traditional lunch at one local farm.",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "After lunch get ready for a 4x4 vintage car adventure and take a safari tour inside the valley and you will enjoy the flourishing nature, high mountains and   water springs and the captivating beauty of the valley and the amazing feature  of the wadi is a water stream that runs through the western part of the canyon  towards the city of Disah. Numerous palm trees and bushes and high grass flourishes in between the majestic cliffs of the canyon. Enjoy discovering the old engravings on the walls of Nabatean Gate. Hike and nature walk around the valley and followed by coffee/tea with snacks.",
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail:
    //           "Finish the Wadi tour and watch the sunset experience at Hajalah.",
    //       },
    //       {
    //         time: "18:00 PM",
    //         title: "",
    //         detail: "Drive back to Tabuk.",
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail: "Arrive in Tabuk.",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: "Drop off at the hotel and grab your dinner.",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: "Overnight stay at the reserved hotel.",
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: NEOM Tour",
    //     description: [
    //       {
    //         time: "08:00 AM",
    //         title: "Wake up & have your breakfast at the hotel.",
    //         detail:
    //           "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
    //       },
    //       {
    //         time: "08:30 AM",
    //         title: "Proceed to Neom (Accompanied by a guide/driver)",
    //         detail: "",
    //       },
    //       {
    //         time: "09:00 AM",
    //         title: "Ist Stop: Safina Rock Mount (Jabal Safina جبل السفينة)",
    //         detail:
    //           "Start your day journey through time at  Jabal Safina the ship mountain is a mount that takes its name from the way it appears as a ship from a distance in the middle of the Hesma desert (that includes a record of history with Archeological inscriptions on its rocks).You can get closer to the ship and watch the ship mountain & take some pictures and get back to your vehicle for the next stop.",
    //       },
    //       {
    //         time: "09:25 AM",
    //         title: "2nd Stop: Tabuk Local Museum.",
    //         detail:
    //           "Visit the local museum & learn about the history & culture of the city.",
    //       },
    //       {
    //         time: "11:00 AM",
    //         title:
    //           "4th Stop: Mugha’yer Shuaib (Prophet Shuaib) (PBUH) tombs & Cave visit.",
    //         detail:
    //           "Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law. The Mugha’yer Shuaib seems to appear from nowhere in the reddish desert west of Tabuk. Moses (PBUH) lived here a decade under the patronage of the Prophet  Shuaib (PBUH), who had been impressed by Moses’ chivalry and offered his daughter’s hand in marriage. Moses eventually returned to Egypt, but it’s easy to imagine that this beautiful place stayed with him. Although this place has some of the most beautiful monumental tombs typical of the architecture of the ancient Nabatean kingdom and the Nabatean tombs of Mugha’ir Shuaib are accessible to tourists.",
    //       },
    //       {
    //         time: "12:00 AM",
    //         title: "5th Stop: Mose’s (Musa (as) Water well.",
    //         detail:
    //           "Visit the Moses well at Maqna famous for its wells that witnessed an important episode in the life of prophet Mose’s (PBUH) where it is believed that Moses took care of Jethro's (Prophet Shuaib) daughters and helped them take out water for their cattle and he offered to help and this is the first place where Mose's (Musa) met Zippora, Jethro’s daughter in this place and after married with her. Thus Prophet Mose’s settled in Midian and lived there for ten years with his wife and father-in-law.",
    //       },
    //       {
    //         time: "13:00 PM",
    //         title:
    //           "6th Stop: 12 springs of Prophet Mose’s (Musa (as) at Maqna.",
    //         detail:
    //           "12 Springs of Prophet Moses (as) is a historical landmark located in the valley of Maqna, on the coast of the Gulf of Aqaba in northwest Saudi Arabia. The spring of water bubbled up among the palm trees and tall grass and its flow against  gravity in the middle of the desert appears to be nothing less than magical. These springs also pump water into the famous valley of Maqna through the sand holes that are driven by water flowing from the ground.",
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "1 Time for lunch. (Serve en route).",
    //         detail: "",
    //       },
    //       {
    //         time: "14:30 PM",
    //         title: "7th Stop: Wadi Tayyeb Al ism valley (Valley of Moses).",
    //         detail:
    //           "Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his  people out of Egypt.) Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley  overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "8th Stop: Catalina Seaplane Wreckage:",
    //         detail:
    //           "The wreckage of a PBY-5A Catalina, an American military seaplane from the 1930s.It has been laid on the beach since 22nd March 1960, when the retired American businessman Thomas Kendall landed in the water near the Ras Al-Sheikh Hameed, Saudi Arabia for a stopover during this trip around the world with his children and his secretary. They spent the night there but the next  afternoon they were attacked with machine guns and automatic firearms by Bedouins who believed it was an actual military attack! Mr. Kendall tried to start the Catalina but only succeeded to move it over about a kilometer where it ran  aground on a coral reef. Catalina which was abandoned on the beach for many  years has now become an important touristic spot.",
    //       },
    //       {
    //         time: "",
    //         title:
    //           "Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.",
    //         detail: "",
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "Ready for departure and drive to Haql.",
    //         detail: "",
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "Arrive in Haql.",
    //         detail: "",
    //       },
    //       {
    //         time: "",
    //         title: "Drop off at the hotels and grab your dinner at the hotel.",
    //         detail: "",
    //       },
    //       {
    //         time: "",
    //         title: "Overnight stay at the reserved hotel",
    //         detail: "",
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     name: "Day 3: Haql",
    //     description: [
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "Haql is a city in the Northwest of Tabuk near the head of the Gulf of Aqaba, adjacent to Aqaba across the Jordanian border. The coasts of Egypt and Jordan are clearly  visible from the Haql",
    //       },
    //       {
    //         time: "",
    //         title: "Morning breakfast at the hotel",
    //         detail: "",
    //       },
    //       {
    //         time: "",
    //         title:
    //           "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
    //         detail: "",
    //       },
    //       {
    //         time: "8:00 AM",
    //         title: "Proceed to Haql Park.  (Accompanied by a guide/driver).",
    //         detail: "First stop: AlNakheel Park (Located in the main city)",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "Start your tour by visiting the local park and exploring the park and watching the view of the Aqaba gulf coast. Egypt and Jordan are clearly visible from the  Park.",
    //       },
    //       {
    //         time: "11:30 AM",
    //         title: "",
    //         detail:
    //           "Drive to the red sea crossing. Tour the crossing site area including a possible ride on a boat out onto the Gulf of Aqaba (The red sea).Snorkel or Scuba dive or swim at the red sea crossing from the shore around the vibran coral reefs with clear crystal water. (Gears on Demand).",
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "Grab your lunch at the seaside. (Seafood for lunch).",
    //         detail: "",
    //       },
    //       {
    //         time: "15:00 PM",
    //         title: "",
    //         detail:
    //           " Heads to the south of the Haql city and watch the famous Saudi titanic “Georgios G Shipwreckage”.The wreckage of the ship, dubbed by some Saudis as the ‘Saudi Titanic,’ is one of the main tourist attractions in Haql. ‘Georgios G’ was built in England after the end of the Second World War, and in 1958 was launched as a cargo liner owned by several individuals and companies.in 1978, when the vessel became stranded on a reef off the Saudi coast in the Gulf of Aqaba, Now an important part of NEOM’s marine conservation.",
    //       },
    //       {
    //         time: "17:00 PM",
    //         title: "7th Stop: Wadi Tayyeb Al ism valley (Valley of Moses).",
    //         detail:
    //           "Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his  people out of Egypt.) Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley  overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.",
    //       },
    //       {
    //         time: "17:00 PM",
    //         title: "",
    //         detail: `Free time for pictures Enjoy Tea/ coffee with Arabic dates with snacks at the beach.
    //           Enjoy a magical sunset overlooking the sea and capture some sunset moments with your lens to cherish the memory forever.
    //           `,
    //       },
    //       {
    //         time: "18:00 PM",
    //         title: " Get back into vehicles and drive to Tabuk",
    //         detail: "",
    //       },

    //       {
    //         time: "20:00 PM",
    //         title: "Arrive in Tabuk.",
    //         detail: "Drop off at the airport.",
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: "Farewell from the Tabuk airport",
    //       },
    //       {
    //         time: "",
    //         title: "Note: This activity ends back at the meeting point.",
    //         detail: "",
    //       },
    //       {
    //         time: "Meeting And Pickup",
    //         title: "Pickup details.",
    //         detail:
    //           "We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.",
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../tabuk/tabukSummer/GULMARG/01.jpg") },
      { id: 2, imageUrl: require("../tabuk/tabukSummer/GULMARG/02.jpg") },
      
      { id: 4, imageUrl: require("../tabuk/tabukSummer/GULMARG/03 .webp") },
    
      
      
      // { id: 8, imageUrl: require("../tabuk/tabukSummer/GULMARG/03 .webp") },
      { id: 9, imageUrl: require("../tabuk/tabukSummer/GULMARG/04 .jpg") },
      { id: 10, imageUrl: require("../tabuk/tabukSummer/GULMARG/05.webp") },
      { id: 11, imageUrl: require("../tabuk/tabukSummer/GULMARG/06.jpg") },
      
     
      { id: 15, imageUrl: require("../tabuk/tabukSummer/GULMARG/07.webp") },
      { id: 16, imageUrl: require("../tabuk/tabukSummer/GULMARG/08.jpeg") },
     
      { id: 18, imageUrl: require("../tabuk/tabukSummer/GULMARG/10.webp") },
     
      
    ],

    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: "pahalgam-paradise-adventure-awaits-in-every-season",
    name: "Pahalgam Paradise: Adventure Awaits in Every Season",
    // Duration: "3 days and 2 Nights",
    imageUrl: require("../tabuk/10.jpg"),
    description: `
    Take a journey to explore the serene beauty of Pahalgam with our "Discover Spirit of the Pahalgam Winter Package." Nestled in the tranquil valleys of Jammu and Kashmir is Pahalgam, a stunning refuge that comes to life in the winter and exhibits the magnificence of its snow-clad landscapes and frozen rivers. This itinerary has been thoughtfully crafted to provide you the ideal mix of relaxation, discovery, and cultural immersion. In the winter, Pahalgam is a nature lover's and adventurer's dream come true, providing everything from peaceful treks through valleys blanketed in pines to the exhilarating experience of snow sports against the backdrop of the Himalayas. Savour the local cuisine, explore ancient temples, and let Pahalgam's breathtaking winter landscape charm you.`,
    whatToExpect: `
    Upon landing in Kashmir, our representative will meet you at Srinagar airport and transfer you to the hotel. After check-in, relax in your air-conditioned 4x4 SUV. Throughout the journey, your attentive tour leader will provide insights and information, ensuring a comfortable and informative exploration of Kashmir's scenic beauty.`,
    highlights: [
    
"Scenic Beauty: Pahalgam is renowned for its stunning natural beauty, featuring lush green valleys, meandering rivers, and snow-capped peaks.",
"Betaab Valley: This picturesque valley, named after the Bollywood movie 'Betaab,' is a popular tourist attraction known for its charming landscapes and pristine surroundings.",
"Lidder River: The gushing Lidder River adds to the scenic charm of Pahalgam, offering opportunities for trout fishing, river rafting, and leisurely picnics along its banks.",
"Trekking Hub: Pahalgam serves as a base for numerous trekking routes, including the famous trek to the Amarnath Cave and the Kolahoi Glacier trek, attracting adventure enthusiasts from around the world.",
"Pony Rides: Visitors can enjoy pony rides amidst the breathtaking scenery of Pahalgam, exploring the verdant meadows and dense pine forests at a leisurely pace.",
"Golf Course: Pahalgam boasts a beautiful 18-hole golf course surrounded by towering mountains, offering golf enthusiasts a unique and picturesque setting for a round of golf.",
"Local Markets: Pahalgam's vibrant local markets offer a variety of handicrafts, souvenirs, and traditional Kashmiri items, providing visitors with an opportunity to shop for authentic local products.",
"Religious Sites: Pahalgam is home to several ancient temples and shrines, including the Mamleshwar Temple and the ancient Shiva Temple, which hold religious significance and attract pilgrims throughout the year.",
"Seasonal Delights: Whether blanketed in snow during winter or adorned with colorful flowers in spring and summer, Pahalgam offers distinct seasonal delights that make it a year-round destination for travelers seeking natural beauty and adventure.",





,
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [   
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Jabel Al Lawz  & The Land of Midian Tour.",
    //     description: [
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law.",
    //       },
    //       {
    //         time: "8:00 AM",
    //         title: "",
    //         detail:
    //           "Assemble at hotel lobby & picked up from hotel by our guide/driver.",
    //       },
    //       {
    //         time: "8:30 AM",
    //         title: "Proceed to Jabel Al Lawz (Accompanied by a guide/driver).",
    //         detail: `Start your day journey through time at  Jabal Safina the ship mountain is a mount that takes its name from the way it appears as a ship from a distance in the middle of the Hesma desert (that includes a record of history with Archeological inscriptions on its rocks) then head to Neom and visit Tayyeb Al-Ism. It is the first place Moses (Mosa) (PBUH) reached when he brought the people out of Egypt through the red sea. It is one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 kilometers north of the coastal town of Maqna. Red-coloured mountains and deep canyons surround the Wadi Tayyeb al ism valley. This lush oasis was an ancient pit stop for weary travellers. The valley overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and go for a nature walk inside the valley. Take as many pictures as you can so you can look at them every time and remember the fun time you spent there.


            
    //           `,
    //       },

    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Go to the Golden Calf Petroglyph. When the people saw that Moses was so long to come down from the mountain, the Israelites made Aaron an image of a golden calf. Surprisingly, all the above-mentioned traces are found close to Mt. Jabal Maqla. The huge altar, where supposedly the image of the golden calf was placed, cannot be missed. And on the altar's stone are numerous petroglyphs, including the one depicting the people holding a festival and dancing.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `  See the 12-stone altars in front of Jabal Lawz Mountain and proceed to Split Rock Mountain. Examine the Split Rock. The giant rock split is located in the Northwest of Horeb at Jabal Maqla, where Moses struck the rock, and God miraculously provided water from within.
    //        `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Make a stop for lunch at 13:00. Then proceed to midian.`,
    //       },

    //       {
    //         time: "14:00 AM",
    //         title:
    //           "Mugha’yer Shuaib (Prophet Shuaib) (PBUH) tombs & Cave visit.",
    //         detail: `Midian (Al bad) is the land where Mose’s (PBUH) spent 10 years in voluntary exile before returning and freeing its people from Pharaoh Ramses II.) And the homeland of Jethro (Prophet Shuaib (PBUH) who was Mose's Father-in-Law. The Mugha’yer Shuaib seems to appear from nowhere in the reddish desert west of Tabuk. Moses (PBUH) lived here a decade under the patronage of the Prophet Shuaib (PBUH), who had been impressed by Moses’ chivalry and offered his daughter’s hand in marriage. Moses eventually returned to Egypt, but it’s easy to imagine that this beautiful place stayed with him. Although this place has some of the most beautiful monumental tombs typical of the architecture of the ancient Nabatean kingdom and the Nabatean tombs of Mugha’ir Shuaib are accessible to tourists.`,
    //       },
    //       {
    //         time: "15:00 PM",
    //         title: "Mose’s (Musa (as) Water well.",
    //         detail: `Visit the Moses well at Maqna famous for its wells that witnessed an important episode in the life of prophet Mose’s (PBUH) where it is believed that Moses took care of Jethro's (Prophet Shuaib) daughters and helped them take out water for their cattle and he offered to help and this is the first place where Mose's (Musa) met Zippora, Jethro’s daughter in this place and after married with her. Thus Prophet Mose’s settled in Midian and lived there for ten years with his wife and father-in-law.`,
    //       },
    //       {
    //         time: "15:30 PM",
    //         title: "12 springs of Prophet Mose’s (Musa (as) at Maqna.",
    //         detail: `12 Springs of Prophet Moses (as) is a historical landmark located in the valley of Maqna, on the coast of the Gulf of Aqaba in northwest Saudi Arabia. The spring of water bubbled up among the palm trees and tall grass and its flow against gravity in the middle of the desert appears to be nothing less than magical. These springs also pump water into the famous valley of Maqna through the sand holes that are driven by water flowing from the ground.`,
    //       },
    //       {
    //         time: "16:00 PM",
    //         title: "Wadi Tayyeb Al ism valley (Valley of Moses).",
    //         detail: `Wadi Tayyeb Al Ism Valley.(The possible location where Moses brought his people out of Egypt.)
    //           Tayyeb Al-Ism is the first place Mose's (Musa) (PBUH) reached when prophet Mose’s brought the people out of Egypt through the red sea, he crossed the Red Sea (As per researchers) and one of Saudi Arabia’s most stunning natural attractions. Visitors to the valley enjoy one surprise after another. The valley is located on the Gulf of Aqaba, 15 Kilometers north of the coastal town of Maqna. The wadi Tayyeb al ism valley is surrounded by red-colored mountains and deep canyons this lush oasis was an ancient pit stop for weary travelers. The valley overlooks the sea and is known for its captivating nature and crystal clear water stream throughout the year. Hike and nature walks inside the valley and take as many pictures as u can so you can look at them every time and remember the fun time you spent there.
    //           `,
    //       },
    //       {
    //         time: "",
    //         title:
    //           "Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.",
    //         detail: ` `,
    //       },
    //       {
    //         time: "18:30",
    //         title: "",
    //         detail: `Ready for departure to Tabuk.
    //          `,
    //       },
    //       {
    //         time: "20:00",
    //         title: "Arrive in Tabuk .",
    //         detail: `Drop off at the hotels and grab your dinner at the hotel`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Overnight stay at the reserved hotel. `,
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Aldisah Valley Tour.",
    //     description: [
    //       {
    //         time: "08:00 AM",
    //         title: "",
    //         detail:
    //           "Proceed to Aldissa Valley (Wadi Qaraqir) (Accompanied by a guide/driver).",
    //       },
    //       {
    //         time: "08:30 AM",
    //         title: "Tabuk Castle.",
    //         detail: `1st Stop:  Tabuk Castle.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail:
    //           "Start you journey through at Tabuk castle. The castle in the center of the town is the oldest, it was built in 1559 during the reign of the Ottoman Empire. Check out the Tabuk Castle, the Hijaz Railway Station and take some photos and get back to your vehicles and proceed to Alshaq.",
    //       },
    //       {
    //         time: "09:00 AM",
    //         title: "The great Canyon Alshaq (Ledge of Neom).",
    //         detail: `Al-Shaq. This name means the tear (or rip) which makes sense as here the earth has been literally torn apart by tectonic forces. That is the reason why this place is also known as the Great Canyon of Saudi Arabia, and it’s located in the Tabuk region between Jabal Hesma & Wade Qaraqir close to  Shiqry village. Take a foot walk and check out the ledge of NEOM. An overhang offers a perfect perspective for a souvenir picture. You can also check out the famous lion mountain as a mountain resembles the face of a lion. Finish the experience and get back inside the vehicle.`,
    //       },
    //       {
    //         time: "10:30 AM",
    //         title: "Heads to Disah Valley.",
    //         detail: `(Disah is mainly located in the Prince Mohammed bin Salman Natural Reserve) in the Southwest Province of Tabuk.`,
    //       },
    //       {
    //         time: "12:30 PM",
    //         title: ".",
    //         detail: `Arrive in Disah Valley (The Valley of palms). Upon arrival, you’ll see exactly why. The luscious valley floor is surrounded by massive sandstone cliffs and pillars and beautiful lush palm trees that are perfect for exploration and followed by traditional lunch at one local farm.
    //         After lunch get ready for a 4x4 vintage car adventure and take a safari tour inside the valley and you will enjoy the flourishing nature, high mountains and water springs and the captivating beauty of the valley and the amazing feature of the wadi is a water stream that runs through the western part of the canyon towards the city of Disah. Numerous palm trees and bushes and high grass flourishes in between the majestic cliffs of the canyon. Enjoy discovering the old engravings on the walls of Nabatean Gate. Hike and nature walk around the valley and followed by coffee/tea with snacks.
    //         `,
    //       },
    //       {
    //         time: "17:30 AM",
    //         title: "",
    //         detail: `Finish the Wadi tour and watch the sunset experience at Hajalah.`,
    //       },
    //       {
    //         time: "18:00 AM",
    //         title: "",
    //         detail: `Drive back to Tabuk.`,
    //       },
    //       {
    //         time: "20:00 AM",
    //         title: "",
    //         detail: `Arrive in Tabuk. `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `	Drop off at the hotel and grab your dinner.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Overnight stay at the reserved hotel. `,
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     name: "Hesma/Bajdah Tour.",
    //     description: [
    //       {
    //         time: "08:00 PM",
    //         title: "	Wake up & have your breakfast at the hotel.",
    //         detail: `Assemble at hotel lobby & picked up from hotel by our guide/driver.`,
    //       },
    //       {
    //         time: "08:30 PM",
    //         title: "	",
    //         detail: `Proceed to Hesma /Bajdah desert by 4WD vehicles (Accompanied by a guide/driver).`,
    //       },
    //       {
    //         time: "09:30 PM",
    //         title: "	",
    //         detail: `Arrive on site.`,
    //       },

    //       {
    //         time: "",
    //         title: "	",
    //         detail: `	Visit the famous natural arch mount and get close to taking the pictures. `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `Time to go on a safari experience for dashing the dunes in the desert by 4x4. `,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "	",
    //         detail: `Time for lunch. (Picnic lunch will serve en site).
            
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	Visit the Neom caves. (Accompanied by the guide)",
    //         detail: `
            
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `	Free time for pictures & Enjoys Tea/ Fresh Arabic coffee with dates & snacks.
            
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `After going on an adventurous ride through the desert exploring the gold sand landscape. Enjoy a magical sunset overlooking the glowing dunes in gold and capture some sunset moments with your lens to cherish the memory forever.
    //         Time to get ready for departure to Tabuk.
            
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: ` Time to get ready for departure to Tabuk.
            
    //         `,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "	",
    //         detail: `Drive back to Tabuk.
    //         `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "	",
    //         detail: `Arrive in Tabuk.
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `Drop off at the airport or hotel.
    //           `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `Farewell from Airport. 
    //           `,
    //       },
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //           `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //           `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../tabuk/PEHALGAAM/01.jpg") },
      { id: 2, imageUrl: require("../tabuk/PEHALGAAM/02.jpg") },
      { id: 3, imageUrl: require("../tabuk/PEHALGAAM/03.jpg") },
      { id: 4, imageUrl: require("../tabuk/PEHALGAAM/04.jpg") },
      { id: 5, imageUrl: require("../tabuk/PEHALGAAM/05.jpg") },
      { id: 6, imageUrl: require("../tabuk/PEHALGAAM/06.jpg") },
      { id: 7, imageUrl: require("../tabuk/PEHALGAAM/07.png") },
      { id: 8, imageUrl: require("../tabuk/PEHALGAAM/09.jpeg") },
      { id: 9, imageUrl: require("../tabuk/PEHALGAAM/10.jpg") },
      
    ],
    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: "sonmarg-spectrum-nature's-playground",
    name: "Sonmarg Spectrum Nature's Playground",
    // Duration: "3 days and 2 Nights",
    imageUrl: require("../tabuk/sonm.jpg"),
    description: `
    Make an appointment to experience Sonmarg's pristine beauty by purchasing our "Discover Sonmarg Winter Package." Sonmarg, often called the "Meadow of Gold," is a breathtaking winter paradise located in the state of Jammu & Kashmir. This unique package is designed to let you experience the spirit of adventure and tranquil beauty of Sonmarg even in the dead of winter. Admire a picture that offers both peaceful retreat and thrilling outdoor sports, all blanketed in light, powdery snow. In the winter, Sonmarg is a terrific place to go snowboarding down its gentle slopes or sledding across snowy fields for those seeking a mix of activity and relaxation. Discover the serene mountain scenery, marvel at the icy grandeur of the Thajiwas Glacier, and get a taste of the kind, friendly locals.`,
    whatToExpect: `
    Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
    

      "Meadow of Gold: Sonmarg, which translates to 'Meadow of Gold,' is named for the golden hues of its flower-laden meadows during the spring season.",
      "Glacial Beauty: Situated at an altitude of 2,800 meters above sea level, Sonmarg is surrounded by majestic glaciers, including the Thajiwas Glacier, offering breathtaking views of snow-capped peaks and pristine landscapes.",
      "Thajiwas Glacier: A major tourist attraction, the Thajiwas Glacier is accessible via a short trek or pony ride from Sonmarg. Visitors can marvel at the glacier's icy blue formations and enjoy activities like sledding and snowball fights during the winter months.",
      "Adventure Hub: Sonmarg is a paradise for adventure enthusiasts, offering a wide range of activities such as trekking, camping, white-water rafting, and trout fishing in the gushing streams and rivers.",
      "Zoji La Pass: Sonmarg serves as the gateway to the famous Zoji La Pass, which connects the Kashmir Valley with the Ladakh region. The pass offers spectacular views of rugged mountains and is a challenging route for trekkers and bikers.",
      "Alpine Lakes: Sonmarg is dotted with several picturesque alpine lakes, including the serene Krishnasar and Vishansar Lakes, which are surrounded by lush meadows and towering peaks, making them ideal spots for photography and relaxation.",
      "Sind River: The Sind River meanders through Sonmarg, adding to its scenic charm and offering opportunities for angling and leisurely walks along its banks.",
      "Base for Amarnath Yatra: Sonmarg serves as a base camp for the annual Amarnath Yatra pilgrimage to the holy Amarnath Cave, where devotees trek through rugged terrain to seek the blessings of Lord Shiva's ice lingam.",
      "Local Culture: Sonmarg offers glimpses into the traditional way of life of the local Gujjar and Bakarwal communities, who are known for their hospitality, handicrafts, and unique cultural practices.",
      "Seasonal Delights: Sonmarg is a year-round destination, each season offering its own charm and activities. Whether it's skiing in winter, trekking in summer, or witnessing the bloom of wildflowers in spring, Sonmarg promises unforgettable experiences throughout the year.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [   
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Alula",
    //     description: [
    //       {
    //         time: "8:30 AM",
    //         title: "",
    //         detail:
    //           "Proceed to winter park and depart to Hegra explore Hegra (Madyan Saleh).",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: ".",
    //         detail: `Start your tour by visiting Saudi Arabia’s first UNESCO world heritage site and the largest preserved site of the Nabatean civilization south of Petra. Home to more than 110 tombs carved from giant rock formations, this ancient site is open for visitors to explore and learn about the culture, traditions, and history of its people. Plus, you’ll see Jabal Ithlib and the Tomb of Lihyan son of Kuza. Hegra Get more from the archaeological site with a guide who knows the history. No need to worry about your entrance fee to Hegra..
    //           `,
    //       },
    //       {
    //         time: "12:00 PM",
    //         title: "",
    //         detail: `finish Hegra tour and back to winter park and explore the winter park and its market.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Proceed to one local farm & have your lunch. (Farm or in a restaurant).
    //         Take a tour to one Alula oasis & Explore the Resonant Shell (A physical and spiritual experience within the Alula Oasis) and next you will drive to visit the Alula old town and its market & explore Saudi's Ancient village "AlUla old town" and experience the understanding, continuity, and evolution of the historic routes for trade and pilgrimage, travelers, and permanent settlers congregated through the ages. AlUla Old Town offers researchers unique oral histories and living memory that the area’s other heritage sites cannot. With 900 houses, 500 shops, and 5 town squares, you can explore a maze of remnants of original stone and mud brick buildings and enjoy views of an AlUla Castle dating back to the 10th century, afterwards head to Aljadidah. (Aljadidah is home to restaurants, shops and more. Al Jadidah is the place where the world’s largest hand painted carpet lines the road for visitors. 
    //         `,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: ` Proceed to Elephant rock and Visit the famous Elephant (Jabal Al Fil) rock, this spectacular rock formation resembles an elephant with its trunk touching the ground, , is one of Aula's geomorphological wonders and became one of AlUla iconic landmarks. Elephant Rock, also known as Jabal AlFil, one of Aula's many geological marvels. As the sun sets, colors dance in the sky and this epic elephant turns a magnificent shade of red.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `Get back to your vehicles and drive to the Accommodation. `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Drop off at your stay and grab your dinner at the hotel restaurant.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Overnight stay at the reserved hotel. `,
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Alula",
    //     description: [
    //       {
    //         time: "08:30 AM",
    //         title: " Morning breakfast at the hotel restaurant.",
    //         detail:
    //           "Assemble at the hotel lobby & Proceed to winter park and depart to Dadan/Ikmah.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "Departs to Dadan/Ikmah experience.",
    //         detail: `Start visiting Dadan, the ancient Kingdom of the Lihyanite and Dadanite civilizations. Next, a bus service will take you on a 10-minute drive to Jabal Ikmah situated in a stunning desert canyon, this open-air library has the most impressive collection of inscriptions of the Islamic era dating back to 24AH (644 CE). Thousands of pre-Arabic inscriptions across numerous sites make AlUla an important location in studying the Arabic language and no such site is more significant than Jabal Ikmah, home to the highest concentration of and most varied inscriptions in AlUla. In a beautiful desert landscape warmed by the sun, the largest “open Start your journey through time at Dadan, the ancient Kingdom of the Lihyanite and Dadanite civilizations. Next, a bus service will take you on a 10-minute drive to Jabal Ikmah situated in a stunning desert canyon, this open-air library has the most impressive collection of inscriptions of the Islamic era dating back to 24AH (644 CE). Thousands of pre-Arabic inscriptions across numerous sites make AlUla an important location in studying the Arabic language and no such site is more significant than Jabal Ikmah, home to the highest concentration of and most varied inscriptions in AlUla. In a beautiful desert landscape warmed by the sun, the largest “open library” in Saudi Arabia sits tucked away in a remote canyon valley.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Drive back to winter park after finishing the experience.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Take a tour to Alwaha farm followed by lunch .`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Take a day tour to the famous elephant rock mount and where you will stop for a photo break .Your next step will be to a famous view point namely Harrat Uwayrid .`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Visit to see the Harrat Uwayrid and check out the Octo café and the amazing view over the Dadan, Alula Old town, Alula old fort and the Alula Oasis from the top of the highest mountain. Which is full of many lookout points that offer you wonderful views of the beautiful landscapes. Where we can enjoy watching the stony terrain of black lava from a height.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Enjoy a magical sunset at Harrat view point and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "19:00 AM",
    //         title:
    //           "Get back to your vehicles and drive to the Accommodation. .",
    //         detail: `Drop off at your stay and grab your dinner at the hotel restaurant.Overnight stay at the reserved hotel. `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Overnight stay at the reserved hotel. `,
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     name: "Day 3 :Alula.",
    //     description: [
    //       {
    //         time: "",
    //         title: "Morning breakfast at the hotel restaurant.",
    //         detail: ``,
    //       },
    //       {
    //         time: "08:00 AM",
    //         title: "",
    //         detail: `Picked up from the hotel and proceed to one of Alula’s deserts. Take a morning desert safari tour & explore the Alula deserts in the morning and possibly see the camel that surrounds the deserts of AlUla.
    //      `,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Visit to see the famous Alula old Arch namely Rainbow Rock arch Mount followed by picnic lunch in the desert.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Check out old engraving sites and some rock art carving sites.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: ` Drive back to the hotel and pick up your bags.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Head to the airport and departure from Alula Airport.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Fare well from Alula Airport by the end of the tour.`,
    //       },
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //           `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //           `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../allula/SONMARG/01.jpg") },
      { id: 2, imageUrl: require("../allula/SONMARG/02.jpg") },
      { id: 3, imageUrl: require("../allula/SONMARG/03.jpg") },
      { id: 4, imageUrl: require("../allula/SONMARG/04.jpg") },
      { id: 5, imageUrl: require("../allula/SONMARG/05.jpg") },
      { id: 6, imageUrl: require("../allula/SONMARG/06.jpg") },
      { id: 7, imageUrl: require("../allula/SONMARG/07.webp") },
      { id: 8, imageUrl: require("../allula/SONMARG/08.jpg") },
      { id: 9, imageUrl: require("../allula/SONMARG/09.jpeg") },
      { id: 10, imageUrl: require("../allula/SONMARG/10.jpg") },
     
    ],

    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id:  'ladakh-heights-adventure-in-the-himalayas',
    name: " Ladakh Heights Adventure in the Himalayas",
    // Duration: "1 week",
    imageUrl: require("../tabuk/ladk.jpg"),
    description: `
    Leh Palace welcomes you with breathtaking views as you start your Ladakh trip. Journey through Chang La Pass to Pangong Lake, surrounded by hills covered in snow. Discover Nubra Valley's sand dunes, double-humped camels, and Diskit Monastery. Explore the monasteries of Thiksey, Hemis, and Shey to learn about the spiritual heritage of Ladakh. Admire the breathtaking scenery and vibrant celebrations of Ladakh.`,
    whatToExpect: `
    Embark on an exciting Ladakh adventure with a knowledgeable guide picking you up from the airport. Start your journey at the historic Leh Palace, offering panoramic views of the mountains. Visit cultural gems like the Diskit Monastery, experience the surreal Magnetic Hill, and explore serene lakes like Pangong and Tso Moriri. Conclude your trip with a stroll through Leh's vibrant markets, leaving you with unforgettable memories of Ladakh's natural wonders and rich cultural heritage.`,
    highlights: [
      "Ladakh is renowned for its high-altitude mountain passes, including the famous Khardung La, Chang La, and Taglang La, offering breathtaking views of the Himalayas and connecting the region with neighboring valleys.",
      "Breathtaking Landscapes: Ladakh boasts surreal landscapes characterized by barren mountains, deep valleys, crystal-clear lakes, and ancient monasteries perched on rocky cliffs, creating a stark and captivating beauty.",
      "Hemis National Park: Home to rare wildlife species such as snow leopards, Tibetan wolves, and Himalayan brown bears, Hemis National Park offers visitors a chance to glimpse these elusive creatures amidst stunning natural surroundings.",
      "Pangong Lake: This iconic lake, known for its mesmerizing shades of blue, stretches across the Indo-China border, offering a surreal sight against the backdrop of rugged mountains. Visitors can enjoy camping by the lake and witnessing its ever-changing hues.",
      "Nubra Valley: Located at a lower altitude than the rest of Ladakh, Nubra Valley is famous for its orchards, sand dunes, and the enchanting Diskit Monastery. Visitors can also enjoy camel rides on the double-humped Bactrian camels in the desert landscape.",
      "Monasteries and Gompas: Ladakh is dotted with ancient monasteries and gompas, each showcasing exquisite Tibetan architecture, vibrant murals, and sacred relics. Highlights include Thiksey Monastery, Hemis Monastery, and Diskit Monastery.",
      "Cultural Heritage: Ladakh is steeped in rich cultural heritage, influenced by Tibetan Buddhism and centuries-old traditions. Visitors can immerse themselves in the local culture by attending festivals, participating in homestays, and interacting with friendly locals.",
      "Adventure Activities: Ladakh offers a plethora of adventure activities, including trekking, mountain biking, river rafting, and camel safaris, attracting thrill-seekers from around the world to its rugged terrain and challenging landscapes.",
      "Ladakhi Cuisine: Visitors can savor authentic Ladakhi cuisine, which includes hearty dishes like Thukpa, Momos, Butter Tea, and Apricot Jam, made from locally sourced ingredients and traditional recipes.",
      "Spiritual Retreat: With its serene atmosphere and majestic surroundings, Ladakh is a haven for spiritual seekers. Whether it's meditating in ancient monasteries, embarking on pilgrimages, or simply soaking in the tranquility of the mountains, Ladakh offers ample opportunities for introspection and spiritual growth.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [   
      "Trusted Agency in Association With budget kashmir.",
      "Create everlasting memories.",
      "Enjoy more value at the best price.",
      "Get served by certified experts.",
      "Guided tours.",
      "Convenient Pickup & Drop off.",],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "",
    //     description: [
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../dissa/LEH LADAKH/01.jpg") },
      { id: 2, imageUrl: require("../dissa/LEH LADAKH/02.jpg") },
      { id: 3, imageUrl: require("../dissa/LEH LADAKH/03.jpg") },
      { id: 4, imageUrl: require("../dissa/LEH LADAKH/04.jpg") },
      { id: 5, imageUrl: require("../dissa/LEH LADAKH/05.jpg") },
      { id: 6, imageUrl: require("../dissa/LEH LADAKH/06.jpg") },
      { id: 7, imageUrl: require("../dissa/LEH LADAKH/07.jpg") },
      { id: 8, imageUrl: require("../dissa/LEH LADAKH/08.jpg") },
      { id: 9, imageUrl: require("../dissa/LEH LADAKH/09.jpg") },
      { id: 10, imageUrl: require("../dissa/LEH LADAKH/10.jpg") },
    
    ],
    tourDetailsInfo: {
      duration: "1",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'explore-the-beauty-of-peer-ki-gali',
    name: " Explore the Beauty of Peer ki Gali",
    // Duration: "1 Day",
    imageUrl: require("../tabuk/pkg.jpg"),
    description: `Take a breathtaking journey through the pristine splendor of Kashmir with our "Full-Day Tour to Peer Ki Gali with Picnic Lunch." The striking Peer Ki Gali Pass on the historic Mughal Road offers broad views of the towering mountains and enormous meadows that define the region's natural splendour. The purpose of this private tour is to completely engross you in the tranquility and breathtaking landscape of one of Kashmir's most beautiful and elevated passes.

    Travelers for generations have been enthralled with the tranquil views that surround you as you follow this historic route. The tour makes stops at significant vantage locations from where you can take in the expansive, unspoiled wilderness of the region. A delicious picnic lunch will be served to you along the way, with the backdrop of`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
     `,
    highlights: [
      "Historical Importance: Peer Ki Gali is a historic mountain pass located on the Mughal Road, an ancient route connecting the Kashmir Valley with the plains of Jammu region.",
      "Scenic Beauty: Situated at an altitude of over 3,500 meters, Peer Ki Gali offers breathtaking views of the surrounding mountains, dense forests, and meandering streams, making it a picturesque stop for travelers.",
      "Legend of Peer Baba: The pass is named after a Sufi saint known as Peer Baba, who is revered by locals and travelers alike. Legend has it that Peer Baba performed miracles and blessed the pass, making it a sacred site for devotees.",
      "Trekking Destination: Peer Ki Gali serves as a starting point or a resting place for trekkers exploring the scenic trails of the Pir Panjal Range and the surrounding Himalayan region.",
      "Cultural Significance: Peer Ki Gali is often visited by devotees seeking blessings from Peer Baba and offering prayers at the shrine located near the pass. The site holds cultural significance for the local Muslim community.",
      "Photography Opportunity: The stunning landscapes and panoramic views of Peer Ki Gali make it a favorite spot for photographers, who capture the natural beauty and serenity of the mountain pass in their lenses.",
      "Adventure Enthusiasts' Paradise: Adventure enthusiasts can engage in activities such as hiking, camping, and nature walks around Peer Ki Gali, immersing themselves in the tranquility of the Himalayan wilderness.",
      "Accessibility: Peer Ki Gali is accessible by road from both the Kashmir Valley and the Jammu region, although the route may be subject to weather conditions, particularly during the winter months when the pass receives heavy snowfall.",
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "",
    //     description: [
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `First stop: Jabel al Lawz/Jabal Maqla. Jabal Al-Lawz, known in Arabic as the "The Almond Mountain '', is the most majestic and the highest mountain in Saudi Arabia. You may find thousands of years old inscriptions, meet locals tending their herds, and see the beautiful but reclusive Arabian ibex if you are fortunate. Jabal Al-Lawz receives winter snowfalls, turning the raw and majestic landscape into a spectacular winter wonderland. In winter, head for Jabal Al-Lawz and enjoy the cooler breezes that wind through the peaks of the surrounding ranges and if you time it just right, enjoy a day of snow and winter fun.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: ` Stop at Elijah's Cave, located at the second highest peak of Jabal Maqla. 
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `Go to the Golden Calf Petroglyph. When the people saw that Moses was so long to come down from the mountain, the Israelites made Aaron an image of a golden calf. Surprisingly, all the above-mentioned traces are found close to Mt. Jabal Maqla. The huge altar, where supposedly the image of the golden calf was placed, cannot be missed. And on the altar's stone are numerous petroglyphs, including the one depicting the people holding a festival and dancing.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `  Make a stop for lunch at 13:00. Then head to the Graveyard.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `See the 12-stone altars in front of Jabal Lawz Mountain and proceed to Split Rock Mountain. Examine the Split Rock. The giant rock split is located in the Northwest of Horeb at Jabal Maqla, where Moses struck the rock, and God miraculously provided water from within.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	",
    //         detail: `  Drive back to Tabuk and Drop off at your stay or airport by the end of the tour.
    //       `,
    //       },
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //       `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //       `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../jabal/PEER KI GALI/01.jpg") },
      { id: 2, imageUrl: require("../jabal/PEER KI GALI/02.jpg") },
      { id: 3, imageUrl: require("../jabal/PEER KI GALI/03.jpg") },
      { id: 4, imageUrl: require("../jabal/PEER KI GALI/04.webp") },
      { id: 5, imageUrl: require("../jabal/PEER KI GALI/05.webp") },
      { id: 6, imageUrl: require("../jabal/PEER KI GALI/06.webp") },
      { id: 7, imageUrl: require("../jabal/PEER KI GALI/07.jpg") },
     
    ],
    tourDetailsInfo: {
      duration: "1",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'pari-mahal-garden-of-dreams',
    name: "Pari Mahal Garden of Dreams",
    // Duration: "1 Day",
    imageUrl: require("../tabuk/pm.webp"),
    description: `Explore Pari Mahal, a historic monument in Srinagar with views of Dal Lake from terraced gardens. Discover its tales and architectural beauty. Pay your respects and take in the serene surroundings as you take in the lake and mountains. A tranquil retreat, Pari Mahal combines culture, history, and natural beauty to provide a unique experience. Enjoy everything that this quaint location has to offer.
    `,
    whatToExpect: ` 
    Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
     
      "Historical Palace: Parimahal, also known as the Palace of Fairies or Fairy Palace, is an ancient historical monument located on Zabarwan Mountain in Srinagar, Kashmir.",
      "Mughal Architecture: Built during the Mughal era in the 17th century, Parimahal showcases exquisite Mughal architecture, characterized by intricate carvings, arched doorways, and beautiful terraced gardens.",
      "Scenic Location: Perched on a hilltop overlooking the picturesque Dal Lake, Parimahal offers stunning panoramic views of the surrounding mountains, valleys, and the shimmering waters of the lake.",
      "Historical Significance: Parimahal served as a royal observatory and a teaching center for astrology and astronomy during the Mughal period, attracting scholars and intellectuals from far and wide.",
      "Terraced Gardens: The terraced gardens surrounding Parimahal are adorned with colorful flowers, fruit trees, and fountains, creating a serene and tranquil atmosphere for visitors to relax and enjoy the beauty of nature.",
      "Light and Sound Show: Parimahal hosts occasional light and sound shows, which narrate the history and significance of the monument, adding an immersive experience for visitors to learn about its rich cultural heritage.",
      "Heritage Conservation: Despite facing periods of neglect and decay, efforts have been made to restore and preserve Parimahal as a cultural heritage site, ensuring that future generations can continue to appreciate its historical and architectural significance.",
      "Photography Spot: Parimahal's scenic location and architectural beauty make it a favorite spot for photographers, who capture its charm and elegance against the backdrop of the surrounding natural landscape.",
      "Accessibility: Parimahal is easily accessible by road from Srinagar city, making it a convenient destination for tourists wishing to explore its historical and cultural attractions.",
      "Cultural Events: Parimahal occasionally hosts cultural events, exhibitions, and art workshops, providing visitors with opportunities to engage with Kashmiri culture and heritage in a unique and immersive setting.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "",

    //     description: [
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //   `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //   `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../neom/parimehal/01.webp") },
      { id: 2, imageUrl: require("../neom/parimehal/02.jpg") },
      { id: 3, imageUrl: require("../neom/parimehal/03.jpg") },
      { id: 4, imageUrl: require("../neom/parimehal/04.jpg") },
      { id: 5, imageUrl: require("../neom/parimehal/05.jpg") },
      { id: 6, imageUrl: require("../neom/parimehal/06.jpg") },
      { id: 7, imageUrl: require("../neom/parimehal/07.jpg") },
      { id: 8, imageUrl: require("../neom/parimehal/08.jpg") },
      { id: 9, imageUrl: require("../neom/parimehal/09.png") },
      { id: 10, imageUrl: require("../neom/parimehal/10.jpg") },
     
    ],
    tourDetailsInfo: {
      duration: "3",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'explore-the-splendor-of-nishat-bagh',
    name: "Explore the Splendor of Nishat Bagh",
    // Duration: "1 Day",
    imageUrl: require("../tabuk/nsht.jpg"),
    description: `Visit Nishat Bagh, also referred to as the "Garden of Joy," for a brief tour and witness its magnificence. Set against the stunning backdrop of Dal Lake and the Zabarwan Mountains, this Mughal garden is a masterwork of landscape architecture with its terraced lawns, vibrant flower beds, and flowing water systems. In addition to offering peaceful views and a peaceful retreat into nature, our tour offers an insight into the garden's rich history and architectural magnificence. Join us for a brief but insightful tour of one of Srinagar's most popular attractions.`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
     
      "Mughal Garden: Nishat Garden is a beautiful Mughal garden located on the eastern side of Dal Lake in Srinagar, Kashmir.",
      "Built by Asif Khan: The garden was built in the 16th century by Asif Khan, the brother of Empress Nur Jahan, during the reign of Mughal Emperor Jahangir.",
      "Terraced Design: Nishat Garden is renowned for its terraced design, with twelve levels representing the twelve signs of the zodiac, each adorned with lush green lawns, vibrant flowerbeds, and cascading fountains.",
      "Panoramic Views: The garden offers panoramic views of Dal Lake and the surrounding Himalayan mountains, providing visitors with a serene and picturesque backdrop to their stroll.",
      "Chinar Trees: Nishat Garden is dotted with majestic Chinar trees, which provide shade and add to the garden's beauty, especially during the autumn season when their leaves turn golden.",
      "Water Channels: Intricate water channels run through the garden, fed by natural springs, creating a soothing ambiance with the gentle sound of flowing water.",
      "Floral Diversity: Nishat Garden boasts a wide variety of flowers, including roses, tulips, daffodils, and lilies, which bloom in vibrant colors throughout the year, delighting visitors with their beauty and fragrance.",
      "Picnic Spot: The tranquil atmosphere and scenic beauty of Nishat Garden make it a popular spot for picnics, family outings, and leisurely walks amidst nature.",
      "Historical Significance: Nishat Garden is steeped in historical significance, having been frequented by Mughal emperors and nobles for centuries, who appreciated its beauty and serenity.",
      "Accessibility: Nishat Garden is easily accessible from Srinagar city by road, making it a convenient destination for tourists wishing to explore its magnificent Mughal architecture and stunning natural surroundings.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "",

    //     description: [
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //   `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //   `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../dune/NISHAT/01.jpg") },
      { id: 2, imageUrl: require("../dune/NISHAT/02.jpg") },
      { id: 3, imageUrl: require("../dune/NISHAT/03.jpg") },
      { id: 4, imageUrl: require("../dune/NISHAT/04.jpg") },
      { id: 5, imageUrl: require("../dune/NISHAT/05.jpg") },
      { id: 6, imageUrl: require("../dune/NISHAT/06.jpg") },
      { id: 7, imageUrl: require("../dune/NISHAT/07.jpg") },
      { id: 8, imageUrl: require("../dune/NISHAT/08.jpg") },
      { id: 9, imageUrl: require("../dune/NISHAT/10.webp") },
      // { id: 10, imageUrl: require("../dune/9.jpg") },
     
    ],
    tourDetailsInfo: {
      duration: "1",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'harwan-capturing-beauty-beyond-words',
    name: "Harwan Capturing Beauty Beyond Words  ",
    // Duration: "1 Day",
    imageUrl: require("../tabuk/hrwn.png"),
    description: `
    Located in Harwan Village, 19 kilometers from Srinagar, Harwan Garden is a popular and outstanding tourist destination. Prized for its pristine beauty, the garden features a canal flanked by Chinar trees and vibrant flowerbeds that emerge from a lake in back. This garden is stunning with its expansive green lawns against a snowy backdrop; it's an excellent spot for picnics and nature treks. Harwan Garden is conveniently located thirty minutes from the Dachi Gam Wildlife Sanctuary and serves as its entrance.`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
     `,
    highlights: [
     
      "Scenic Location: Harwan is a picturesque area located on the outskirts of Srinagar city, nestled amidst the foothills of the Zabarwan Range in Kashmir.",
      "Mughal Garden: Harwan is famous for its ancient Mughal garden, which was originally built by Emperor Shah Jahan in the 17th century.",
      "Chinar Trees: The garden is adorned with majestic Chinar trees, which provide shade and add to the beauty of the surroundings, particularly during the autumn season when their leaves turn golden.",
      "Natural Springs: Harwan is known for its natural springs, which flow through the garden and add to its serenity. Visitors can enjoy the soothing sound of flowing water and relax in the tranquil ambiance.",
      "Picnic Spot: Harwan is a popular picnic spot for locals and tourists alike, offering ample green space, shaded areas, and scenic views for picnicking and leisurely outings with family and friends.",
      "Historical Ruins: In addition to the Mughal garden, Harwan is home to ancient archaeological ruins dating back to the 3rd century BCE, including remnants of Buddhist stupas and monastic cells, which reflect its rich historical heritage.",
      "Harwan Lake: Harwan is situated near a picturesque lake, which adds to the charm of the area and provides opportunities for boating, birdwatching, and enjoying the tranquility of nature.",
      "Hiking Trails: Harwan offers hiking trails amidst the surrounding hills and forests, providing visitors with opportunities to explore the natural beauty and biodiversity of the region.",
      "Cultural Significance: Harwan has cultural significance due to its historical gardens and archaeological sites, attracting history enthusiasts, scholars, and researchers interested in exploring its past.",
      "Accessibility: Harwan is easily accessible from Srinagar city by road, making it a convenient destination for day trips, picnics, and leisurely strolls amidst nature and history.",  
      
      
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Morning Trial Experience. ",
    //     description: [
    //       {
    //         time: "8:00 AM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "08:30 AM",
    //         title: "",
    //         detail: `Drive to the location.`,
    //       },
    //       {
    //         time: "9:30 AM",
    //         title: "",
    //         detail: "arrive at the location.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Preparation for Hiking.`,
    //       },
    //       {
    //         time: "10:15 PM",
    //         title: "",
    //         detail: "start Hiking accompanied by the hiking guide.",
    //       },
    //       {
    //         time: "12:00 PM",
    //         title: "",
    //         detail: `Finish hiking and take a rest.(Refreshments serve in site)`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Take a safari tour around the desert.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Grab your lunch.( Serve at Campsite)`,
    //       },
    //       {
    //         time: "15:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves visit`,
    //       },
    //       {
    //         time: "17:00 PM",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },
    //       {
    //         time: "",
    //         title: "Afternoon trial experience.",
    //         detail: ``,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Assemble at the gathering point & picked up by our guide/driver.`,
    //       },
    //       {
    //         time: "14:30",
    //         title: "",
    //         detail: `Drive to the location.`,
    //       },
    //       {
    //         time: "15:30",
    //         title: "",
    //         detail: `Arrive at the location`,
    //       },
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail: `Preparation for Hike.`,
    //       },
    //       {
    //         time: "16:10:",
    //         title: "",
    //         detail: `start Hiking accompanied by the hiking guide.`,
    //       },
    //       {
    //         time: "17:00 PM",
    //         title: "",
    //         detail: `Finish hiking and Finish hiking and take a rest.(Refreshments serve in site)`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Relax and enjoy Arabic coffee, Tea with snacks.`,
    //       },
    //       {
    //         time: "",
    //         title:
    //           "	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.",
    //         detail: ``,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail: `Head to campsite & Have dinner at the campsite.`,
    //       },
    //       {
    //         time: "21:00 PM",
    //         title: "	",
    //         detail: `Drive back to Tabuk.
    //         `,
    //       },
    //       {
    //         time: "22:00 PM",
    //         title: "	",
    //         detail: `Arrive in Tabuk.
    //         `,
    //       },
    //       {
    //         time: "22:00 PM",
    //         title: "	",
    //         detail: `Farewell from Tabuk
    //         `,
    //       },
    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //   `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../hike/harwan/01.jpg") },
      { id: 2, imageUrl: require("../hike/harwan/02.webp") },
      { id: 3, imageUrl: require("../hike/harwan/03.jpg") },
      { id: 4, imageUrl: require("../hike/harwan/04.jpg") },
      { id: 5, imageUrl: require("../hike/harwan/05.jpg") },
      { id: 6, imageUrl: require("../hike/harwan/06.jpg") },
      { id: 7, imageUrl: require("../hike/harwan/07.jpg") },
      { id: 8, imageUrl: require("../hike/harwan/08.png") },
      
    ],
    tourDetailsInfo: {
      duration: "1",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'shalimar-a-tale-of-mughal-majesty',
    name: "Shalimar A Tale of Mughal Majesty",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/shmlmr.jpeg"),
    description: `Northeast of Dal Lake is connected to the Mughal garden Shalimar Bagh in Srinagar, Jammu & Kashmir, India, by a river. It's also known as Shalimar Gardens, Faiz Baksh, and Farah Baksh. Known locally as "The Garden of Delight," Nishat Bagh is another popular waterfront garden. The Mughal Emperor Jahangir built the Bagh in 1619. Considered the best example of Mughal gardening is the Bagh. Today, it serves as a public park and is referred to be the "Crown of Srinagar."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      "Mughal Garden: Shalimar Bagh is a historic Mughal garden located in Srinagar, Kashmir, built by Emperor Jahangir in the 17th century.",
      "Symbol of Love: Shalimar Bagh was originally constructed as a symbol of love for Jahangir's wife, Empress Nur Jahan, and is renowned for its romantic ambiance and architectural beauty.",
      "Terraced Design: The garden is designed in terraces, with three main levels representing the traditional Islamic concept of paradise, each adorned with fountains, pavilions, and meticulously manicured lawns.",
      "Chinar Trees: Shalimar Bagh is adorned with majestic Chinar trees, which provide shade and add to the beauty of the surroundings, particularly during the autumn season when their leaves turn golden.",
      "Water Channels: Intricate water channels run through the garden, fed by natural springs, creating a soothing ambiance with the gentle sound of flowing water.",
      "Floral Diversity: Shalimar Bagh boasts a wide variety of flowers, including roses, tulips, daffodils, and lilies, which bloom in vibrant colors throughout the year, delighting visitors with their beauty and fragrance.",
      "Historical Significance: Shalimar Bagh is steeped in historical significance, having been frequented by Mughal emperors and nobles for centuries, who appreciated its beauty and serenity.",
      "Light and Sound Show: Shalimar Bagh occasionally hosts light and sound shows, which narrate the history and significance of the garden, adding an immersive experience for visitors to learn about its rich cultural heritage.",
      "Picnic Spot: The tranquil atmosphere and scenic beauty of Shalimar Bagh make it a popular spot for picnics, family outings, and leisurely walks amidst nature.",
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    images1: [
      { id: 1, imageUrl: require("../camp/shalimar/01.jpg") },
      { id: 2, imageUrl: require("../camp/shalimar/02.jpg") },
      { id: 3, imageUrl: require("../camp/shalimar/03.jpg") },
      { id: 4, imageUrl: require("../camp/shalimar/04.jpg") },
      { id: 5, imageUrl: require("../camp/shalimar/05.jpg") },
      { id: 6, imageUrl: require("../camp/shalimar/06.jpeg") },
      { id: 7, imageUrl: require("../camp/shalimar/06.jpg") },
      { id: 8, imageUrl: require("../camp/shalimar/07.jpg") },
      { id: 9, imageUrl: require("../camp/shalimar/09.jpg") },
      { id: 10, imageUrl: require("../camp/shalimar/10.jpg") },
    
    ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'dal-Lake-delights-a-tranquil-escape-in-srinagar',
    name: "Dal Lake Delights A Tranquil Escape in Srinagar",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/dlke.jpg"),
    description: `Dal Lake, sometimes called the "jewel of Srinagar," is a gorgeous expanse of water that perfectly captures the peace and beauty of Kashmir. This famous lake, surrounded by towering mountains and luxuriant vegetation, is lined with colourful houseboats, known as "Shikaras," and floating gardens, which combine to create a captivating image. Indulge in leisurely Shikara rides, stroll around the vibrant floating markets, or just take in the tranquilly of the environment. In addition to being a beautiful site, Dal Lake represents Kashmir's rich cultural history and draws visitors from all over the world with its enduring appeal."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      "Iconic Waterbody: Dal Lake is the most iconic and picturesque lake in Srinagar, Kashmir, often referred to as the 'Jewel in the Crown of Kashmir' or the 'Heart of Srinagar.'",
      "Natural Beauty: Surrounded by snow-capped mountains, lush greenery, and charming houseboats, Dal Lake offers stunning panoramic views and a serene ambiance that captivates visitors.",
      "Houseboats: Dal Lake is famous for its traditional houseboats, intricately decorated and equipped with modern amenities, offering a unique and unforgettable accommodation experience amidst the tranquil waters.",
      "Shikara Rides: Visitors can enjoy leisurely shikara rides on Dal Lake, gliding through its calm waters while admiring the breathtaking scenery, colorful floating gardens, and bustling markets along the shores.",
      "Floating Gardens: Dal Lake is renowned for its unique floating gardens, known as 'Rad' or 'Raad' in Kashmiri, where vegetables and flowers are cultivated on floating beds of weeds and soil, showcasing the ingenuity of the local people.",
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    // images1: [
    //   { id: 1, imageUrl: require("../camp/shalimar/01.jpg") },
    //   { id: 2, imageUrl: require("../camp/shalimar/02.jpg") },
    //   { id: 3, imageUrl: require("../camp/shalimar/03.jpg") },
    //   { id: 4, imageUrl: require("../camp/shalimar/04.jpg") },
    //   { id: 5, imageUrl: require("../camp/shalimar/05.jpg") },
    //   { id: 6, imageUrl: require("../camp/shalimar/06.jpeg") },
    //   { id: 7, imageUrl: require("../camp/shalimar/06.jpg") },
    //   { id: 8, imageUrl: require("../camp/shalimar/07.jpg") },
    //   { id: 9, imageUrl: require("../camp/shalimar/09.jpg") },
    //   { id: 10, imageUrl: require("../camp/shalimar/10.jpg") },
    
    // ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: 'kashmir-honeymoon-delights',
    name: "Kashmir Honeymoon Delights",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/couples-kashmir.webp"),
    description: `
    Honeymoon packages usually consist of selected events designed for newlyweds, romantic accommodations, and extra luxuries. They frequently include locations that are well-known for their romantic allure, including remote resorts, quaint hill villages, or far-flung vacation spots. In order to provide the newlyweds lifelong memories, these packages could include things like candlelit meals, spa treatments, daring excursions, and sightseeing tours. Honeymoon packages are designed to give couples a stress-free and unforgettable experience as they commemorate the start of their marriage."~,"`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Luxurious accommodations at renowned hotels or houseboats nestled amidst the breathtaking scenery of Kashmir.",
      "Majestic views of snow-capped mountains, serene lakes, and lush valleys, creating a romantic ambiance.",
      "Exclusive experiences like Shikara rides on Dal Lake, trekking in the Himalayas, and picnics in scenic meadows.",
      "Romantic amenities such as candlelit dinners, couples' spa treatments, and private tours to iconic landmarks.",
      "Personalized services to enhance the honeymoon experience, including customized itineraries and attentive hospitality.",





     
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    // images1: [
    //   { id: 1, imageUrl: require("../camp/shalimar/01.jpg") },
    //   { id: 2, imageUrl: require("../camp/shalimar/02.jpg") },
    //   { id: 3, imageUrl: require("../camp/shalimar/03.jpg") },
    //   { id: 4, imageUrl: require("../camp/shalimar/04.jpg") },
    //   { id: 5, imageUrl: require("../camp/shalimar/05.jpg") },
    //   { id: 6, imageUrl: require("../camp/shalimar/06.jpeg") },
    //   { id: 7, imageUrl: require("../camp/shalimar/06.jpg") },
    //   { id: 8, imageUrl: require("../camp/shalimar/07.jpg") },
    //   { id: 9, imageUrl: require("../camp/shalimar/09.jpg") },
    //   { id: 10, imageUrl: require("../camp/shalimar/10.jpg") },
    
    // ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },
  {
    id: "discovering-gurez-valley's-beauty",
    name: " Discovering Gurez Valley's Beauty",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/gurez-valley.webp"),
    description: `
    
    The Gurez Valley in Kashmir is a tranquil oasis surrounded by snow-capped peaks and verdant meadows, and it is one of the most beautiful places in the northern Himalayas. The valley's many communities, which include those who speak Shina, add to its cultural tapestry and extend a warm welcome to guests. Gurez is a popular destination for outdoor enthusiasts who like to travel, hike, and fish in an unspoiled setting. The valley is more attractive due to its strategic location close to the Line of Control and old trade routes, both of which are rich in history. Gurez Valley, which is only accessible in the summer, offers visitors a peaceful getaway and an amazing opportunity to discover Kashmir's best-kept secret."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      "Remote Valley: Gurez is a remote and picturesque valley located in the high Himalayas in the northern part of Jammu and Kashmir, near the Line of Control (LoC) with Pakistan.",
      "Breathtaking Scenery: Gurez is renowned for its breathtaking natural beauty, characterized by towering mountains, lush green meadows, pristine rivers, and snow-capped peaks, making it a paradise for nature lovers and photographers.",
      "Isolated Location: Due to its isolated location and challenging terrain, Gurez remained relatively inaccessible to outsiders for many years, preserving its pristine environment and traditional way of life.",
      "Culture and Heritage: Gurez is home to a unique culture and heritage, influenced by its diverse inhabitants, including Shina-speaking people, Gujjars, and nomadic tribes. Visitors can experience the rich cultural traditions, hospitality, and cuisine of the region.",
      "Trekking and Adventure: Gurez offers excellent opportunities for trekking and adventure activities amidst its rugged terrain and picturesque landscapes. Popular trekking routes include the trek to Gangbal Lake and the Razdan Pass.",





     
     
    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    // images1: [
    //   { id: 1, imageUrl: require("../camp/shalimar/01.jpg") },
    //   { id: 2, imageUrl: require("../camp/shalimar/02.jpg") },
    //   { id: 3, imageUrl: require("../camp/shalimar/03.jpg") },
    //   { id: 4, imageUrl: require("../camp/shalimar/04.jpg") },
    //   { id: 5, imageUrl: require("../camp/shalimar/05.jpg") },
    //   { id: 6, imageUrl: require("../camp/shalimar/06.jpeg") },
    //   { id: 7, imageUrl: require("../camp/shalimar/06.jpg") },
    //   { id: 8, imageUrl: require("../camp/shalimar/07.jpg") },
    //   { id: 9, imageUrl: require("../camp/shalimar/09.jpg") },
    //   { id: 10, imageUrl: require("../camp/shalimar/10.jpg") },
    
    // ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },

  // {
  //   id: 'best-shri-mata-vaishno-devi-packages',
  //   name: "Best Shri Mata Vaishno Devi Packages",
  //   // Duration: "1 days   ",
  //   imageUrl: require("../tabuk/Vaishno_Devi.webp"),
  //   description: `
    
  //   Vaishno Devi, nestled in the Trikuta Mountains of Jammu and Kashmir, is a revered Hindu pilgrimage site attracting millions annually. Devotees embark on a challenging trek or opt for helicopter services to reach the shrine dedicated to Mata Vaishno Devi. Along the journey, they encounter symbolic points like the holy cave and Charan Paduka spring. Culminating in darshan of the divine rocks, the pilgrimage offers a spiritually enriching experience amidst breathtaking mountain scenery."`,
  //   whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
  //   Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
  //   `,
  //   highlights: [
  //     " Spiritual Destination: Vaishno Devi, situated in the Trikuta Mountains of Jammu and Kashmir, is a revered Hindu pilgrimage site dedicated to Mata Vaishno Devi.",
  //     "Pilgrimage Trek: Millions of devotees undertake a challenging trek or utilize helicopter services to reach the shrine, traversing rugged terrain and steep pathways.",
  //     "Symbolic Points: Along the journey, pilgrims encounter significant landmarks such as the holy cave and Charan Paduka spring, each holding symbolic importance in Hindu mythology.",
  //     "Divine Darshan: The pilgrimage culminates in the darshan of the three divine rocks or 'Pindies,' representing the manifestations of Mata Vaishno Devi, believed to fulfill devotees' wishes.",





     
     
  //   ],
  //   whybook: {
  //     title: "Why book your holidays with us:",
  //     hollydays: [
  //       "Trusted Agency in Association With budget kashmir.",
  //       "Create everlasting memories.",
  //       "Enjoy more value at the best price.",
  //       "Get served by certified experts.",
  //       "Guided tours.",
  //       "Convenient Pickup & Drop off.",
  //     ],
  //   },
  //   // daySchadule: [
  //   //   {
  //   //     id: 1,
  //   //     name: "Day 1:Tabuk",
  //   //     description: [
  //   //       {
  //   //         time: "16:00 PM",
  //   //         title: "",
  //   //         detail:
  //   //           "Assemble at the gathering point & picked up by our guide/driver.",
  //   //       },
  //   //       {
  //   //         time: "16:30 PM",
  //   //         title: "",
  //   //         detail: `Drive to the campsite.`,
  //   //       },
  //   //       {
  //   //         time: "17:30 PM",
  //   //         title: "",
  //   //         detail: "Arrive at the Campsite.",
  //   //       },
  //   //       {
  //   //         time: "19:00 PM",
  //   //         title: "",
  //   //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
  //   //       },
  //   //       {
  //   //         time: "20:00 PM",
  //   //         title: "",
  //   //         detail:
  //   //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
  //   //       },
  //   //       {
  //   //         time: "21:00",
  //   //         title: "",
  //   //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
  //   //       },
         
  //   //     ],
  //   //   },
  //   //   {
  //   //     id: 2,
  //   //     name: "Day 2: Tabuk",
  //   //     description: [
  //   //       {
  //   //         time: "8: 30 AM",
  //   //         title: "",
  //   //         detail: "wake up and have your breakfast at the campsite.",
  //   //       },
  //   //       {
  //   //         time: "10:00 AM",
  //   //         title: "",
  //   //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
  //   //       },
  //   //       {
  //   //         time: "13:00 PM",
  //   //         title: "",
  //   //         detail: `Lunch Time.`,
  //   //       },
  //   //       {
  //   //         time: "14:00 PM",
  //   //         title: "",
  //   //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
  //   //       },
  //   //       {
  //   //         time: "",
  //   //         title: "",
  //   //         detail: `Finish Neom caves visit and get back to your vehicles.`,
  //   //       },
  //   //       {
  //   //         time: "",
  //   //         title: "",
  //   //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
  //   //       },
  //   //       {
  //   //         time: "17:30 PM",
  //   //         title: "",
  //   //         detail: `Departure to Tabuk.`,
  //   //       },
  //   //       {
  //   //         time: "18:30 PM",
  //   //         title: "",
  //   //         detail: `Arrive in Tabuk.`,
  //   //       },

  //   //       {
  //   //         time: "",
  //   //         title: "	Note: This activity ends back at the meeting point.",
  //   //         detail: `
  //   //         `,
  //   //       },
  //   //       {
  //   //         time: "Meeting and Pickup:",
  //   //         title: "Pickup details.",
  //   //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
  //   //         `,
  //   //       },
  //   //     ],
  //   //   },
  //   // ],
  //   // images1: [
  //   //   { id: 1, imageUrl: require("../camp/shalimar/01.jpg") },
  //   //   { id: 2, imageUrl: require("../camp/shalimar/02.jpg") },
  //   //   { id: 3, imageUrl: require("../camp/shalimar/03.jpg") },
  //   //   { id: 4, imageUrl: require("../camp/shalimar/04.jpg") },
  //   //   { id: 5, imageUrl: require("../camp/shalimar/05.jpg") },
  //   //   { id: 6, imageUrl: require("../camp/shalimar/06.jpeg") },
  //   //   { id: 7, imageUrl: require("../camp/shalimar/06.jpg") },
  //   //   { id: 8, imageUrl: require("../camp/shalimar/07.jpg") },
  //   //   { id: 9, imageUrl: require("../camp/shalimar/09.jpg") },
  //   //   { id: 10, imageUrl: require("../camp/shalimar/10.jpg") },
    
  //   // ],
  //   tourDetailsInfo: {
  //     duration: "2",
  //     tourType: "Daily",
  //     groupSize: "4 People Minimum",
  //     language: "English & Arabic",
  //   },
  // },

//   {
//     id: 'best-amarnath-yatra-packages',
//     name: "Best Amarnath Yatra Packages",
//     // Duration: "1 days   ",
//     imageUrl: require("../tabuk/amarnath.jpg"),
//     description: `
    
   
// The Amarnath Yatra, a revered Hindu pilgrimage to the Amarnath Cave in Jammu and Kashmir's Himalayas, draws devotees seeking blessings from Lord Shiva. Pilgrims navigate rugged mountain terrain, either trekking or using helicopter services. En route, they're mesmerized by the scenic beauty of snow-capped peaks and valleys. At the cave, they encounter the sacred ice lingam, a natural formation believed to embody Lord Shiva. The yatra offers not just a physical journey but a deeply spiritual experience for participants."`,
//     whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
//     Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
//     `,
//     highlights: [
//       " Spiritual Pilgrimage: The Amarnath Yatra is a revered Hindu pilgrimage to the Amarnath Cave in the Himalayas of Jammu and Kashmir, dedicated to Lord Shiva.",
//       "Challenging Terrain: Pilgrims navigate rugged mountain paths, facing challenging terrain and weather conditions during the journey.",
//       "Natural Beauty: En route, participants are treated to breathtaking views of snow-capped peaks, pristine valleys, and lush landscapes.",
//       "Sacred Ice Lingam: At the cave, devotees encounter the natural ice lingam, believed to symbolize Lord Shiva's presence and power.",





     
     
//     ],
//     whybook: {
//       title: "Why book your holidays with us:",
//       hollydays: [
//         "Trusted Agency in Association With budget kashmir.",
//         "Create everlasting memories.",
//         "Enjoy more value at the best price.",
//         "Get served by certified experts.",
//         "Guided tours.",
//         "Convenient Pickup & Drop off.",
//       ],
//     },
//     // daySchadule: [
//     //   {
//     //     id: 1,
//     //     name: "Day 1:Tabuk",
//     //     description: [
//     //       {
//     //         time: "16:00 PM",
//     //         title: "",
//     //         detail:
//     //           "Assemble at the gathering point & picked up by our guide/driver.",
//     //       },
//     //       {
//     //         time: "16:30 PM",
//     //         title: "",
//     //         detail: `Drive to the campsite.`,
//     //       },
//     //       {
//     //         time: "17:30 PM",
//     //         title: "",
//     //         detail: "Arrive at the Campsite.",
//     //       },
//     //       {
//     //         time: "19:00 PM",
//     //         title: "",
//     //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
//     //       },
//     //       {
//     //         time: "20:00 PM",
//     //         title: "",
//     //         detail:
//     //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
//     //       },
//     //       {
//     //         time: "21:00",
//     //         title: "",
//     //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
//     //       },
         
//     //     ],
//     //   },
//     //   {
//     //     id: 2,
//     //     name: "Day 2: Tabuk",
//     //     description: [
//     //       {
//     //         time: "8: 30 AM",
//     //         title: "",
//     //         detail: "wake up and have your breakfast at the campsite.",
//     //       },
//     //       {
//     //         time: "10:00 AM",
//     //         title: "",
//     //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
//     //       },
//     //       {
//     //         time: "13:00 PM",
//     //         title: "",
//     //         detail: `Lunch Time.`,
//     //       },
//     //       {
//     //         time: "14:00 PM",
//     //         title: "",
//     //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
//     //       },
//     //       {
//     //         time: "",
//     //         title: "",
//     //         detail: `Finish Neom caves visit and get back to your vehicles.`,
//     //       },
//     //       {
//     //         time: "",
//     //         title: "",
//     //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
//     //       },
//     //       {
//     //         time: "17:30 PM",
//     //         title: "",
//     //         detail: `Departure to Tabuk.`,
//     //       },
//     //       {
//     //         time: "18:30 PM",
//     //         title: "",
//     //         detail: `Arrive in Tabuk.`,
//     //       },

//     //       {
//     //         time: "",
//     //         title: "	Note: This activity ends back at the meeting point.",
//     //         detail: `
//     //         `,
//     //       },
//     //       {
//     //         time: "Meeting and Pickup:",
//     //         title: "Pickup details.",
//     //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
//     //         `,
//     //       },
//     //     ],
//     //   },
//     // ],
//     // images1: [
//     //   { id: 1, imageUrl: require("../camp/shalimar/01.jpg") },
//     //   { id: 2, imageUrl: require("../camp/shalimar/02.jpg") },
//     //   { id: 3, imageUrl: require("../camp/shalimar/03.jpg") },
//     //   { id: 4, imageUrl: require("../camp/shalimar/04.jpg") },
//     //   { id: 5, imageUrl: require("../camp/shalimar/05.jpg") },
//     //   { id: 6, imageUrl: require("../camp/shalimar/06.jpeg") },
//     //   { id: 7, imageUrl: require("../camp/shalimar/06.jpg") },
//     //   { id: 8, imageUrl: require("../camp/shalimar/07.jpg") },
//     //   { id: 9, imageUrl: require("../camp/shalimar/09.jpg") },
//     //   { id: 10, imageUrl: require("../camp/shalimar/10.jpg") },
    
//     // ],
//     tourDetailsInfo: {
//       duration: "2",
//       tourType: "Daily",
//       groupSize: "4 People Minimum",
//       language: "English & Arabic",
//     },
//   },

  {
    id: 'doodhpathri-dream-a-journey-to-remember',
    name: "Doodhpathri Dream A Journey to Remember",
    // Duration: "1 days   ",
    imageUrl: require("../tabuk/Doodpathri-3.jpg"),
    description: `
    
   

    Doodhpathri, nestled in the picturesque Budgam district of Jammu and Kashmir, is a pristine valley renowned for its natural beauty and tranquil ambiance. Aptly named 'Valley of Milk,' it boasts lush green meadows, dense forests, and gurgling streams that create a serene and captivating landscape. Visitors can indulge in activities like picnicking, horse riding, and trekking, while immersing themselves in the breathtaking vistas and refreshing air of this idyllic Himalayan retreat."`,
    whatToExpect: ` Arrive at  International Airport in Srinagar as per your scheduled flight. Welcome to Srinagar, the summer capital of Jammu and Kashmir, India.
    Upon arrival, our representative will greet you outside the arrival gate (after collecting your baggage). You will be transferred to your hotel for check-in. After leaving your bags, hop into your comfortable 4x4 SUV car to begin your exploration. Throughout the journey, your tour leader will provide insightful commentary, enhancing your experience of the scenic beauty and cultural richness of Kashmir.
    `,
    highlights: [
      " Natural Beauty: Doodhpathri, located in the Budgam district of Jammu and Kashmir, is celebrated for its stunning natural landscapes, including lush meadows, dense forests, and flowing streams.",
      "Tranquil Environment: The valley offers a peaceful and serene ambiance, making it an ideal retreat for those seeking relaxation and rejuvenation amidst nature.",
      "Outdoor Activities: Visitors can engage in various recreational activities such as picnicking, horse riding, trekking, and nature walks, allowing them to immerse themselves fully in the valley's beauty.",
      "Accessibility: Doodhpathri is easily accessible by road from Srinagar, making it a convenient day trip or weekend getaway for tourists and locals alike.",


    ],
    whybook: {
      title: "Why book your holidays with us:",
      hollydays: [
        "Trusted Agency in Association With budget kashmir.",
        "Create everlasting memories.",
        "Enjoy more value at the best price.",
        "Get served by certified experts.",
        "Guided tours.",
        "Convenient Pickup & Drop off.",
      ],
    },
    // daySchadule: [
    //   {
    //     id: 1,
    //     name: "Day 1:Tabuk",
    //     description: [
    //       {
    //         time: "16:00 PM",
    //         title: "",
    //         detail:
    //           "Assemble at the gathering point & picked up by our guide/driver.",
    //       },
    //       {
    //         time: "16:30 PM",
    //         title: "",
    //         detail: `Drive to the campsite.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: "Arrive at the Campsite.",
    //       },
    //       {
    //         time: "19:00 PM",
    //         title: "",
    //         detail: `•	Relax at the campsite followed by Arabic coffee, tea with snacks. `,
    //       },
    //       {
    //         time: "20:00 PM",
    //         title: "",
    //         detail:
    //           "Have dinner at the campsite around the campfire. (BBQ dinner with grills).",
    //       },
    //       {
    //         time: "21:00",
    //         title: "",
    //         detail: `overnight sleep at the campsite. (Tents & sleeping bags will be provided).`,
    //       },
         
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Day 2: Tabuk",
    //     description: [
    //       {
    //         time: "8: 30 AM",
    //         title: "",
    //         detail: "wake up and have your breakfast at the campsite.",
    //       },
    //       {
    //         time: "10:00 AM",
    //         title: "",
    //         detail: `Take a morning desert safari tour & explore the Tabuk deserts in the morning and possibly see the camel that surrounds the deserts of Tabuk.`,
    //       },
    //       {
    //         time: "13:00 PM",
    //         title: "",
    //         detail: `Lunch Time.`,
    //       },
    //       {
    //         time: "14:00 PM",
    //         title: "",
    //         detail: `Take a tour to the Neom caves and check out the old inscriptions and rock art formation.(accompanied by the guide).`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `Finish Neom caves visit and get back to your vehicles.`,
    //       },
    //       {
    //         time: "",
    //         title: "",
    //         detail: `•	Enjoy a magical sunset and capture some sunset moments with your lens to cherish the memory forever.`,
    //       },
    //       {
    //         time: "17:30 PM",
    //         title: "",
    //         detail: `Departure to Tabuk.`,
    //       },
    //       {
    //         time: "18:30 PM",
    //         title: "",
    //         detail: `Arrive in Tabuk.`,
    //       },

    //       {
    //         time: "",
    //         title: "	Note: This activity ends back at the meeting point.",
    //         detail: `
    //         `,
    //       },
    //       {
    //         time: "Meeting and Pickup:",
    //         title: "Pickup details.",
    //         detail: `We will coordinate a proper pickup time for the time of the day your tour falls on. We will call or reach by email or whatsapp within 24 hours to set up your time and place of pickup. We provide round-trip transportation from any Hotel wherever you are staying in Tabuk City.
    //         `,
    //       },
    //     ],
    //   },
    // ],
    // images1: [
    //   { id: 1, imageUrl: require("../camp/shalimar/01.jpg") },
    //   { id: 2, imageUrl: require("../camp/shalimar/02.jpg") },
    //   { id: 3, imageUrl: require("../camp/shalimar/03.jpg") },
    //   { id: 4, imageUrl: require("../camp/shalimar/04.jpg") },
    //   { id: 5, imageUrl: require("../camp/shalimar/05.jpg") },
    //   { id: 6, imageUrl: require("../camp/shalimar/06.jpeg") },
    //   { id: 7, imageUrl: require("../camp/shalimar/06.jpg") },
    //   { id: 8, imageUrl: require("../camp/shalimar/07.jpg") },
    //   { id: 9, imageUrl: require("../camp/shalimar/09.jpg") },
    //   { id: 10, imageUrl: require("../camp/shalimar/10.jpg") },
    
    // ],
    tourDetailsInfo: {
      duration: "2",
      tourType: "Daily",
      groupSize: "4 People Minimum",
      language: "English & Arabic",
    },
  },


  
];
