import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import galleryGxl1Img from '../../../assets/images/gallary/g-xl-1.png';
import { SRLWrapper } from 'simple-react-lightbox';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { tourData } from '../../../assets/data/data';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { Space, Spin } from 'antd';
import { RWebShare } from 'react-web-share';
import shareIcon from '../../../assets/share-alt-solid-60 (2).png';
import { Helmet } from 'react-helmet';

const PackageDetails = props => {
  // let data = props.location?.state?.row;

  let packageId = props?.match?.params?.id;
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const [startDate, setStartDate] = useState(new Date());
  const [bookingData, SetbookingData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [newData, setNewData] = useState(
    tourData.filter(item => item.id == packageId)[0]
  );

  let helmetData = tourData.filter(item => item.id == packageId)[0];

  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const submitData = data => {
    setDisabled(true);
    let recipient = 'ashirholidays@gmail.com;zaid.redstaglabs@gmail.com;';

    let reqData = {
      to: recipient,
      name: data.name,
      email: data.email,
      subject: 'Visitor for Ashir Holidays',
      message: `<!DOCTYPE html>
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <title> Travel Email</title>
            <style>
              img {
                border: none;
                -ms-interpolation-mode: bicubic;
                max-width: 100%;
              }
              body {
                background-color: #f6f6f6;
                font-family: sans-serif;
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 1.4;
                margin: 0;
                padding: 0;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
              }
              table {
                border-collapse: separate;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                width: 100%;
              }
              table td {
                font-family: sans-serif;
                font-size: 14px;
                vertical-align: top;
              } /* ------------------------------------- BODY & CONTAINER ------------------------------------- */
              .body {
                background-color: #f6f6f6;
                width: 100%;
              } /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
              .container {
                display: block;
                margin: 0 auto !important; /* makes it centered */
                max-width: 580px;
                padding: 10px;
                width: 580px;
              }
              .content {
                box-sizing: border-box;
                display: block;
                margin: 0 auto;
                max-width: 580px;
                padding: 10px;
              }
              .main {
                background: #ffffff;
                border-radius: 3px;
                width: 100%;
              }
              .wrapper {
                box-sizing: border-box;
                padding: 20px;
              }
              .content-block {
                padding-bottom: 10px;
                padding-top: 10px;
              }
              .footer {
                clear: both;
                margin-top: 10px;
                text-align: center;
                width: 100%;
              }
              .footer td,
              .footer p,
              .footer span,
              .footer a {
                color: #999999;
                font-size: 12px;
                text-align: center;
              }
              h1,
              h2,
              h3,
              h4 {
                color: #000000;
                font-family: sans-serif;
                font-weight: 400;
                line-height: 1.4;
                margin: 0;
                margin-bottom: 30px;
              }
              h1 {
                font-size: 35px;
                font-weight: 300;
                text-align: center;
                text-transform: capitalize;
              }
              p,
              ul,
              ol {
                font-family: sans-serif;
                font-size: 14px;
                font-weight: normal;
                margin: 0;
                margin-bottom: 15px;
              }
              p li,
              ul li,
              ol li {
                list-style-position: inside;
                margin-left: 5px;
              }
              a {
                color: #3498db;
                text-decoration: underline;
              }
              .btn {
                box-sizing: border-box;
                width: 100%;
              }
              .btn > tbody > tr > td {
                padding-bottom: 15px;
              }
              .btn table {
                width: auto;
              }
              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }
              .btn a {
                background-color: #ffffff;
                border: solid 1px #3498db;
                border-radius: 5px;
                box-sizing: border-box;
                color: #3498db;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                font-weight: bold;
                margin: 0;
                padding: 12px 25px;
                text-decoration: none;
                text-transform: capitalize;
              }
              .btn-primary table td {
                background-color: #3498db;
              }
              .btn-primary a {
                background-color: #3498db;
                border-color: #3498db;
                color: #ffffff;
              }
              .last {
                margin-bottom: 0;
              }
              .first {
                margin-top: 0;
              }
              .align-center {
                text-align: center;
              }
              .align-right {
                text-align: right;
              }
              .align-left {
                text-align: left;
              }
              .clear {
                clear: both;
              }
              .mt0 {
                margin-top: 0;
              }
              .mb0 {
                margin-bottom: 0;
              }
              .preheader {
                color: transparent;
                display: none;
                height: 0;
                max-height: 0;
                max-width: 0;
                opacity: 0;
                overflow: hidden;
                mso-hide: all;
                visibility: hidden;
                width: 0;
              }
              .powered-by a {
                text-decoration: none;
              }
              hr {
                border: 0;
                border-bottom: 1px solid #f6f6f6;
                margin: 20px 0;
              }
              @media only screen and (max-width: 620px) {
                table.body h1 {
                  font-size: 28px !important;
                  margin-bottom: 10px !important;
                }
                table.body p,
                table.body ul,
                table.body ol,
                table.body td,
                table.body span,
                table.body a {
                  font-size: 16px !important;
                }
                table.body .wrapper,
                table.body .article {
                  padding: 10px !important;
                }
                table.body .content {
                  padding: 0 !important;
                }
                table.body .container {
                  padding: 0 !important;
                  width: 100% !important;
                }
                table.body .main {
                  border-left-width: 0 !important;
                  border-radius: 0 !important;
                  border-right-width: 0 !important;
                }
                table.body .btn table {
                  width: 100% !important;
                }
                table.body .btn a {
                  width: 100% !important;
                }
                table.body .img-responsive {
                  height: auto !important;
                  max-width: 100% !important;
                  width: auto !important;
                }
              }
              @media all {
                .ExternalClass {
                  width: 100%;
                }
                .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                  line-height: 100%;
                }
                .apple-link a {
                  color: inherit !important;
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  text-decoration: none !important;
                }
                #MessageViewBody a {
                  color: inherit;
                  text-decoration: none;
                  font-size: inherit;
                  font-family: inherit;
                  font-weight: inherit;
                  line-height: inherit;
                }
                .btn-primary table td:hover {
                  background-color: #34495e !important;
                }
                .btn-primary a:hover {
                  background-color: #34495e !important;
                  border-color: #34495e !important;
                }
              }
        </style>
        </head>
        <body>
          <span class="preheader"
            >This is preheader text. Some clients will show this text as a
            preview.</span
          >
          <table
            role="presentation"
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="body"
          >
            <tr>
              <td>&nbsp;</td>
              <td class="container">
                <div class="content">
                  <table role="presentation" class="main">
                    <tr>
                      <td class="wrapper">
                        <table
                          role="presentation"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tr>
                            <td>
                              <p>I am <b>${data?.name}</b> , i want to book <b>${newData?.name}</b> with tickets Type  <b>${data?.type}</b>.  The Travellers 
                        with  number of adults <b>${data?.adult} </b> and number of childrens <b>${data?.child} <b/>.</br>The Travel  booking  is on   ${data?.date}<p/>
                         <br/>
                              <p>
                               Message: <b>${data?.message}</b>
                              </p>
                              <br/>
                              <span>Yours Sincerely,</span><br/>
                              <span><b> Name:${data?.name}</b></span><br/>
                              <span>Email: ${data?.email},</span><br/>
                              <span>Phone: ${data?.phone},</span><br/>
  
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <div class="footer">
                    <table
                      role="presentation"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                    >
                  
                      <tr>
                        <td class="content-block powered-by">
                          Powered by
                          <a href="https://redstaglabs.com" target="_blank">Red Stag Labs</a>.
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </td>
              <td>&nbsp;</td>
            </tr>
          </table>
        </body>
      </html>`,
    };
    let req = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(reqData),
    };

    fetch('https://us-central1-redstaglabs.cloudfunctions.net/contact_us', req)
      .then(response => response.json())
      .then(data => {
        if (data) {
          addToast('Email is Sent Successfully', { appearance: 'success' });
          reset();
          setDisabled(false);
        } else {
          addToast('Error Occured While Sending Email', {
            appearance: 'error',
          });
          setDisabled(false);
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>{helmetData.name}</title>
        {/* <meta title={helmetData?.description}/> */}
        <meta
          name='description'
          content='Our tailor-made tours promise unforgettable experiences. Explore our packages now and embark on a journey crafted just for you.'
        />
        <meta name='keywords' content='react, meta tags, seo' />
        <meta name='author' content='Your Name' />
        <meta
          property='og:title'
          content=' Explore Tour Packages at AboutAshir Holidays - Adventure Awaits!'
        />
        <meta
          property='og:description'
          content='Our tailor-made tours promise unforgettable experiences. Explore our packages now and embark on a journey crafted just for you.'
        />
        <meta property='og:image' content='https://example.com/image.jpg' />
        <meta property='og:url' content='https://example.com/my-page' />
        <meta name='twitter:title' content='My Page Title' />
        <meta
          name='twitter:description'
          content='Our tailor-made tours promise unforgettable experiences. Explore our packages now and embark on a journey crafted just for you.'
        />
        <meta name='twitter:image' content='https://example.com/image.jpg' />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
      {/* ===============  breadcrumb area start =============== */}

      <div className='breadcrumb-area'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='breadcrumb-wrap'>
                <h2>{newData?.name}</h2>

                <ul className='breadcrumb-links'>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                    <i className='bx bx-chevron-right' />
                  </li>
                  <li>Package Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
      <div className='package-details-wrapper pt-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='package-details'>
                <div className='package-thumb'>
                  <img src={newData?.imageUrl?.default} alt='' />
                </div>
                <div className='package-header'>
                  <div className='package-title'>
                    <h3>{newData?.name}</h3>
                    {/* <strong>
                      <i className="flaticon-arrival" />
                      Mount Dtna, Spain
                    </strong> */}
                  </div>
                  {/* <div className="pd-review">
                    <p>Excellent</p>
                    <ul>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bxs-star" />
                      </li>
                      <li>
                        <i className="bx bx-star" />
                      </li>
                    </ul>
                    <p>800 Review</p>
                  </div> */}
                </div>
                <div className='p-short-info'>
                  <div className='single-info'>
                    <i className='flaticon-clock' />
                    <div className='info-texts'>
                      <strong>Duration</strong>
                      <p>{newData?.tourDetailsInfo?.duration}</p>
                    </div>
                  </div>
                  <div className='single-info'>
                    <i className='flaticon-footprints' />
                    <div className='info-texts'>
                      <strong>Tour Type</strong>
                      <p>{newData?.tourDetailsInfo?.tourType}</p>
                    </div>
                  </div>
                  <div className='single-info'>
                    <i className='flaticon-traveller' />
                    <div className='info-texts'>
                      <strong>Group Size</strong>
                      <p>{newData?.tourDetailsInfo?.groupSize}</p>
                    </div>
                  </div>
                  <div className='single-info'>
                    <i className='flaticon-translate' />
                    <div className='info-texts'>
                      <strong>Languages</strong>
                      <p>{newData?.tourDetailsInfo?.language}</p>
                    </div>
                  </div>
                </div>
                <div className='package-tab'>
                  <ul className='nav nav-pills' id='pills-tab' role='tablist'>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link active'
                        id='pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-home'
                        type='button'
                        role='tab'
                        aria-controls='pills-home'
                        aria-selected='true'
                      >
                        <i className='flaticon-info' />
                        Information
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='pills-profile-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-profile'
                        type='button'
                        role='tab'
                        aria-controls='pills-profile'
                        aria-selected='false'
                      >
                        <i className='flaticon-clipboard' />
                        What to expect
                      </button>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className='nav-link'
                        id='pills-contact-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-contact'
                        type='button'
                        role='tab'
                        aria-controls='pills-contact'
                        aria-selected='false'
                      >
                        {' '}
                        <i className='flaticon-gallery' />
                        Our Gallary
                      </button>
                    </li>
                  </ul>
                  <div
                    className='tab-content p-tab-content'
                    id='pills-tabContent'
                  >
                    <div
                      className='tab-pane fade show active'
                      id='pills-home'
                      role='tabpanel'
                      aria-labelledby='pills-home-tab'
                    >
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='tab-content-1'>
                            <div className='p-overview'>
                              <h5>Overview</h5>
                              <p>{newData?.description}</p>
                            </div>
                            <div className='p-highlight'>
                              <h5>Tour Highlights</h5>
                              <ul>
                                {newData?.highlights?.map(item => {
                                  return (
                                    <li>
                                      <i className='bx bx-circle' />{' '}
                                      <p>{item}</p>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>

                            <div className='p-highlight'>
                              <h5>{newData?.whybook?.title}</h5>
                              <ul>
                                {newData?.whybook?.hollydays?.map(item => {
                                  return (
                                    <li>
                                      <i className='bx bx-circle' />{' '}
                                      <p>{item}</p>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                            {/* <div className="p-details-table">
                              <table className="table caption-top">
                                <tbody>
                                  <tr>
                                    <td>Destination</td>
                                    <td>New York City</td>
                                  </tr>
                                  <tr>
                                    <td>Depature</td>
                                    <td>Yes Required</td>
                                  </tr>
                                  <tr>
                                    <td>Departure Time</td>
                                    <td>01 April, 2021 10.00AM</td>
                                  </tr>
                                  <tr>
                                    <td>Return Time</td>
                                    <td>08 April, 2021 10.00AM</td>
                                  </tr>
                                  <tr>
                                    <td>Included</td>
                                    <td>
                                      <ul className="table-list-allow">
                                        <li>
                                          <i className="bx bx-check" />{" "}
                                          Specilaized Bilingual Guide
                                        </li>
                                        <li>
                                          {" "}
                                          <i className="bx bx-check" /> Private
                                          Transport
                                        </li>
                                        <li>
                                          <i className="bx bx-check" /> Entrance
                                          Fees
                                        </li>
                                        <li>
                                          <i className="bx bx-check" /> Box
                                          Lunch,Water,Dinner and Snacks
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Excluded</td>
                                    <td>
                                      <ul className="table-list-disallow">
                                        <li>
                                          {" "}
                                          <i className="bx bx-x" /> Additional
                                          Services
                                        </li>
                                        <li>
                                          <i className="bx bx-x" /> Insurance
                                        </li>
                                        <li>
                                          <i className="bx bx-x" /> Drink
                                        </li>
                                        <li>
                                          <i className="bx bx-x" /> Tickets
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2} className="table-bottom">
                                      <i className="flaticon-public-transport" />
                                      Travel With Bus
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div> */}
                            {/* <div className="p-rationg">
                              <h5>Rating</h5>
                              <div className="rating-card">
                                <div className="r-card-avarag">
                                  <h2>4.9</h2>
                                  <h5>Excellent</h5>
                                </div>
                                <div className="r-card-info">
                                  <ul>
                                    <li>
                                      <strong>Accommodation</strong>
                                      <ul className="r-rating">
                                        <li>
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <strong>Transport</strong>
                                      <ul className="r-rating">
                                        <li>
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bx-star" />
                                          <i className="bx bx-star" />
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <strong>Comfort</strong>
                                      <ul className="r-rating">
                                        <li>
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bx-star" />
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <strong>Hospitality</strong>
                                      <ul className="r-rating">
                                        <li>
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bx-star" />
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <strong>Food</strong>
                                      <ul className="r-rating">
                                        <li>
                                          <i className="bx bxs-star" />
                                          <i className="bx bxs-star" />
                                          <i className="bx bx-star" />
                                          <i className="bx bx-star" />
                                          <i className="bx bx-star" />
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='pills-profile'
                      role='tabpanel'
                      aria-labelledby='pills-profile-tab'
                    >
                      <div className='tab-content-2'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div className='p-timeline-overview'>
                              <h5>Overview</h5>
                              <p>{newData?.whatToExpect}</p>
                            </div>
                            <ul className='p-timeline'>
                              {newData?.daySchadule?.map(row => {
                                return (
                                  <li>
                                    <div className='timeline-index'>
                                      <div className='index-circle'>
                                        <h5>0{row.id}</h5>
                                      </div>
                                    </div>
                                    <div className='timeline-content '>
                                      <h5>{row.name}</h5>
                                      {/* <strong>10.00 AM to 10.00 PM</strong> */}

                                      {/* <div className='tab-content-2'>
                              <ul>
                              {row.id === 1 && row?.description?.map((item,index) => {
                                  return (
                                    <li  style={{position: "related"}} key={index}>
                                      
                                     
                                      <i className='bx bx-circle' />{' '}
                                      <p  >{item}</p>=
                                    </li>
                                  );
                                })}
                              </ul>
                              </div> */}

                                      {/* <ul>
                                        <li />
                                        <li>
                                          <i className='bx bx-check' />
                                          Specilaized Bilingual Guide
                                        </li>
                                        <li />
                                        <li>
                                          <i className='bx bx-check' />
                                          Private Transport
                                        </li>
                                        <li />
                                        <li>
                                          <i className='bx bx-check' />
                                          Entrance Fees
                                        </li>
                                        <li />
                                        <li>
                                          <i className='bx bx-check' />
                                          Box Lunch,Water,Dinner and Snacks
                                        </li>
                                      </ul> */}
                                      <div className='tab-content-1'>
                                        <div className='p-highlight'>
                                          <ul>
                                            {row.description?.map(
                                              (item, index) => {
                                                return (
                                                  <p
                                                    style={{
                                                      lineHeight: '1px',
                                                    }}
                                                    key={index}
                                                  >
                                                    <p
                                                      style={{
                                                        color: '#ff7f47',
                                                        fontSize: '18px',
                                                        fontWeight: '500',
                                                      }}
                                                    >
                                                      {item.time}
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      {item.title}
                                                    </p>
                                                    <p>{item.detail}</p>
                                                  </p>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      <div className="tab-contant-3">
                        <SRLWrapper>
                          <div className="row">
                            <div className="col-lg-8 col-md-8">
                              <div className="package-grid-one">
                                {data?.images?.map((img) => {
                                  return (
                                    <div className="single-grid">
                                      <Link
                                        to={img.imageUrl}
                                        className="g-img-sm-1 main-gallary"
                                      >
                                        {console.info(
                                          img.imageUrl.default,
                                          "789y"
                                        )}
                                        <img
                                          src={img.imageUrl.default}
                                          alt="gallary-img"
                                        />
                                      </Link>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </SRLWrapper>
                      </div>
                    </div> */}
                    <div
                      className='tab-pane fade'
                      id='pills-contact'
                      role='tabpanel'
                      aria-labelledby='pills-contact-tab'
                    >
                      <div className='tab-contant-3'>
                        <SRLWrapper>
                          <div className='row'>
                            <div className='col-lg-8 col-md-8'>
                              <div className='package-grid-one'>
                                <div className='single-grid'>
                                  {newData?.images1?.map((item, index) => {
                                    return (
                                      <Link to={item.imageUrl.default}>
                                        <img
                                          src={item.imageUrl.default}
                                          alt='gallary-img'
                                          style={{
                                            width: '300px',
                                            height: '220px',
                                          }}
                                        />
                                      </Link>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </SRLWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RWebShare
                data={{
                  url: window.location.href.toString(),
                  title: 'AEKT Package Share',
                }}
                onClick={() => console.log('shared successfully!')}
              >
                <img
                  src={shareIcon}
                  width='40px'
                  style={{ cursor: 'pointer' }}
                />
              </RWebShare>
            </div>

            <div className='col-lg-4'>
              <div className='package-d-sidebar'>
                <div className='row'>
                  <div className='col-lg-12 col-md-6'>
                    <div className='p-sidebar-form'>
                      <form onSubmit={handleSubmit(submitData)}>
                        <h5 className='package-d-head'>Book This Package</h5>
                        <div className='row'>
                          <div className='col-lg-12'>
                            {errors.name && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  mb: 2,
                                }}
                              >
                                Field Is required
                              </p>
                            )}
                            <input
                              {...register('name', { required: true })}
                              // onChange={e =>
                              //   SetbookingData({
                              //     ...bookingData,
                              //     name: e.target.value,
                              //   })
                              // }
                              id='name'
                              name='name'
                              type='text'
                              placeholder='Your Full Name'
                            />
                          </div>
                          <div className='col-lg-12'>
                            {errors.email && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  mb: 2,
                                }}
                              >
                                Field Is required
                              </p>
                            )}
                            <input
                              {...register('email', { required: true })}
                              // onChange={e =>
                              //   SetbookingData({
                              //     ...bookingData,
                              //     email: e.target.value,
                              //   })
                              // }
                              id='email'
                              name='email'
                              type='email'
                              placeholder='Your Email'
                            />
                          </div>
                          <div className='col-lg-12'>
                            {errors.phone && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  mb: 2,
                                }}
                              >
                                Field Is required
                              </p>
                            )}
                            <input
                              {...register('phone', {
                                required: true,
                                minLength: 10,
                              })}
                              // onChange={e =>
                              //   SetbookingData({
                              //     ...bookingData,
                              //     phone: e.target.value,
                              //   })
                              // }
                              id='phone'
                              name='phone'
                              type='tel'
                              placeholder='Phone'
                            />
                          </div>
                          <div className='col-lg-12'>
                            {errors.type && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  mb: 2,
                                }}
                              >
                                Field Is required
                              </p>
                            )}
                            <select
                              {...register('type', { required: true })}
                              // onChange={e =>
                              //   SetbookingData({
                              //     ...bookingData,
                              //     type: e.target.value,
                              //   })
                              // }
                              id='type'
                              className='form-select'
                              name='type'
                              aria-label='Default select example'
                            >
                              <option selected value=''>
                                Tickets Type
                              </option>
                              <option value={'Travel With Bus'}>
                                Travel With Bus
                              </option>
                              <option value={'Travel With Car'}>
                                Travel With Car
                              </option>
                              <option value={'Travel With Plane'}>
                                Travel With Plane
                              </option>
                            </select>
                          </div>
                          <div className='col-lg-6'>
                            {errors.adult && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  mb: 2,
                                }}
                              >
                                Field Is required
                              </p>
                            )}
                            <select
                              {...register('adult', { required: true })}
                              // onChange={e =>
                              //   SetbookingData({
                              //     ...bookingData,
                              //     adult: e.target.value,
                              //   })
                              // }
                              id='adult'
                              name='adult'
                              className='form-select'
                              aria-label='Default select example'
                            >
                              <option selected value=''>
                                Adult
                              </option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                              <option value={11}>11</option>
                              <option value={12}>12</option>
                              <option value={13}>13</option>
                              <option value={14}>14</option>
                              <option value={15}>15</option>
                            </select>
                          </div>
                          <div className='col-lg-6'>
                            {errors.child && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  mb: 2,
                                }}
                              >
                                Field Is required
                              </p>
                            )}
                            <select
                              {...register('child', { required: true })}
                              // onChange={e =>
                              //   SetbookingData({
                              //     ...bookingData,
                              //     child: e.target.value,
                              //   })
                              // }
                              id='child'
                              name='child'
                              // className='form-select'
                              // aria-label='Default select example'
                            >
                              <option selected value=''>
                                Child
                              </option>
                              <option value={0}>0</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                            </select>
                          </div>
                          <div className='col-lg-12'>
                            {errors.email && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  mb: 2,
                                }}
                              >
                                Field Is required
                              </p>
                            )}
                            <div
                              className='calendar-input'
                              id='packageCalenderMainDiv'
                            >
                              <input
                                type='date'
                                {...register('date', { required: true })}
                                id='date'
                                name='date'
                                className='input-field check-in'
                                placeholder='dd-mm-yy'
                              />
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            {errors.message && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  mb: 2,
                                }}
                              >
                                Field Is required
                              </p>
                            )}
                            <textarea
                              {...register('message', { required: true })}
                              // onChange={e =>
                              //   SetbookingData({
                              //     ...bookingData,
                              //     message: e.target.value,
                              //   })
                              // }
                              id='message'
                              name='message'
                              cols={30}
                              rows={7}
                              placeholder='Message'
                              defaultValue={''}
                            />
                          </div>
                          {disabled && (
                            <Space size='middle'>
                              <Spin size='large' />
                            </Space>
                          )}
                          <div className='col-lg-12'>
                            <input
                              type='submit'
                              disabled={disabled}
                              defaultValue='Book Now'
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='col-lg-12 col-md-6'>
                    <div className='p-sidebar-cards mt-40'>
                      <h5 className='package-d-head'>Popular Packages</h5>
                      <ul className='package-cards'>
                        {tourData.map(item => {
                          return (
                            <li className='package-card-sm'>
                              <div className='p-sm-img'>
                                <img src={item.imageUrl.default} alt='' />
                              </div>
                              <div className='package-info'>
                                <div className='package-date-sm'>
                                  {/* <strong>
                                    <i className="flaticon-calendar" />5 Days/6
                                    night
                                  </strong> */}
                                </div>
                                <h3>
                                  <i className='flaticon-arrival' />
                                  <Link
                                    to={{
                                      pathname: `${process.env.PUBLIC_URL}/package-details/${item.id}`,
                                      state: {
                                        row: item,
                                      },
                                    }}
                                  >
                                    {item.name}
                                  </Link>
                                </h3>
                                {/* <h5>
                                  <span>$180</span>/ Per Person
                                </h5> */}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-lg-12 col-md-6">
                    <div className="p-sidebar-organizer mt-40">
                      <h5 className="package-d-head">Organized By</h5>
                      <div className="organizer-card">
                        <div className="organizer-img">
                          <img src={organizer} alt="" />
                        </div>
                        <div className="organizer-info">
                          <h5>Travelhotel</h5>
                          <p>Member since 2021</p>
                          <ul className="organizer-rating">
                            <li>
                              <i className="bx bxs-star" />
                            </li>
                            <li>
                              <i className="bx bxs-star" />
                            </li>
                            <li>
                              <i className="bx bxs-star" />
                            </li>
                            <li>
                              <i className="bx bxs-star" />
                            </li>
                            <li>
                              <i className="bx bx-star" />
                            </li>
                          </ul>
                          <h5>500 Reviews</h5>
                        </div>
                      </div>
                      <div className="p-ask-btn">
                        <Link to={`${process.env.PUBLIC_URL}/contact`}>
                          ASK A QUESTION
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-12 col-md-6">
                    <div className="p-sidebar-banner mt-40">
                      <img src={sidebarBannar} alt="" className="img-fluid" />
                      <div className="sidebar-banner-overlay">
                        <div className="overlay-content">
                          <h3>Get 50% Off In Dubai Tour</h3>
                          <div className="sidebar-banner-btn">
                            <Link to={`#`}>Book Now</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
  // }
};

export default PackageDetails;
