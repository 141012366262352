import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from "react-responsive-carousel";
import { Helmet } from 'react-helmet';
import { tourData } from '../../../assets/data/data';

function Packages() {
  const handlePush = item => {
    console.info(item, 'item');
    localStorage.setItem('Package', JSON.stringify(item));
  };
  return (
    <>
     <Helmet>
          <title>Ashir Holidays Explore dream destinations</title>
          <meta name='description' content='Our tailor-made tours promise unforgettable experiences. Explore our packages now and embark on a journey crafted just for you.' />
          <meta name='keywords' content='react, meta tags, seo' />
          <meta name='author' content='Your Name' />
          <meta property='og:title' content=' Explore Tour Packages at KashTours - Adventure Awaits!' />
          <meta title='  Explore Tour Packages at KashTours - Adventure Awaits!'/>
          <meta 
            property='og:description'
            content='Our tailor-made tours promise unforgettable experiences. Explore our packages now and embark on a journey crafted just for you.'
          />
          <meta property='og:image' content='https://example.com/image.jpg' />
          <meta property='og:url' content='https://example.com/my-page' />
          <meta name='twitter:title' content='My Page Title' />
          <meta
            name='twitter:description'
            content='Our tailor-made tours promise unforgettable experiences. Explore our packages now and embark on a journey crafted just for you.'
          />
          <meta name='twitter:image' content='https://example.com/image.jpg' />
          <meta name='twitter:card' content='summary_large_image' />
        </Helmet>
      {/* ===============  Package  area start =============== */}
      <div className='package-area pt-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='section-head pb-45'>
                <h5>Choose Your Package</h5>
                 <h1>Select Your best Package For Your Travel</h1>
              </div>
            </div>
          </div>

          <div className='row'>
            {tourData.map(item => {
              return (
                <div
                  className='col-lg-4 col-md-6 col-sm-6 wow fadeInUp animated'
                  data-wow-duration='1500ms'
                  data-wow-delay='0ms'
                  style={{ height: '400px' }}
                >
                  <div className='package-card'>
                    <div
                      className='package-thumb'
                      onClick={() => handlePush(item)}
                    >
                      <Link
                        to={{
                          pathname: `${process.env.PUBLIC_URL}/package-details/${item.id}`,
                        }}
                      >
                        <img
                          src={item.imageUrl.default}
                          alt=''
                          className='img-fluid'
                          style={{ height: '200px' }}
                        />
                      </Link>
                    </div>
                    <div
                      className='package-details'
                      style={{ height: '170px' }}
                    >
                      <div className='package-info'>
                        {/* <h5>
                          <span>$180</span>/Per Person
                        </h5> */}
                        {/* <h5>
                          <i className='flaticon-calendar' />
                          {item?.Duration}
                        </h5> */}
                      </div>
                      <h3>
                        <i className='flaticon-arrival' />

                        <Link
                          to={{
                            pathname: `${process.env.PUBLIC_URL}/package-details/${item.id}`,
                            state: {
                              row: item,
                            },
                          }}
                        >
                          {item.name}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* ===============  Package  area end =============== */}
    </>
  );
}

export default Packages;
