import React, { Component } from "react";
import MainBanner from "./MainBanner";
import Packages from "./Packages";
import Destinations from "./Destinations";
import Blog from "./Blog";
import Features from "./Features";
import Review from "./Review";
import "./style.css";
import Achievement from "./Achievement";
import { Carousel } from "react-responsive-carousel";


import Img1 from "../../../assets/images/carousel-img/New folder/img1.jpeg";
import Img2 from "../../../assets/images/carousel-img/New folder/img2.jpeg";
import Img3 from "../../../assets/images/carousel-img/New folder/img3.jpeg";
import Img4 from "../../../assets/images/carousel-img/New folder/img4.jpeg";



import Img8 from "../../../assets/images/carousel-img/New folder/img5.jpeg";
import Img9 from "../../../assets/images/carousel-img/New folder/img6.jpeg";
import Img10 from "../../../assets/images/carousel-img/New folder/img7.jpeg";
import Img11 from "../../../assets/images/carousel-img/New folder/img8.jpeg";
import Img12 from "../../../assets/images/carousel-img/New folder/img9.jpeg";
// import Img13 from "../../../assets/images/carousel-img/pic24.jpg";
import Img14 from "../../../assets/images/carousel-img/New folder/img11.jpeg";
import Img15 from "../../../assets/images/carousel-img/New folder/img12.jpeg";
import Img16 from "../../../assets/images/carousel-img/New folder/img13.jpeg";
import Img36 from "../../../assets/images/carousel-img/New folder/img14.jpeg";
import Img35 from "../../../assets/images/carousel-img/New folder/img15.jpeg";
import Img34 from "../../../assets/images/carousel-img/New folder/img16.jpeg";
import Img33 from "../../../assets/images/carousel-img/New folder/img17.jpeg";
import Img32 from "../../../assets/images/carousel-img/New folder/img18.jpeg";
import Img29 from "../../../assets/images/carousel-img/New folder/img19.jpeg";

import Img28 from "../../../assets/images/carousel-img/New folder/img20.jpeg";
import Img27 from "../../../assets/images/carousel-img/New folder/img22.jpg";
import Img25 from "../../../assets/images/carousel-img/New folder/img23.jpg";
import Img50 from "../../../assets/images/carousel-img/New folder/img24.jpg";
import Img49 from "../../../assets/images/carousel-img/New folder/img25.jpg";
import Img61 from "../../../assets/images/carousel-img/New folder/img61.webp";
import Img62 from "../../../assets/images/carousel-img/New folder/flag-cloud.webp";
import Img63 from "../../../assets/images/carousel-img/New folder/top-view-back.webp";
import Img64 from "../../../assets/images/carousel-img/New folder/water-side-picture.webp";
import Img65 from "../../../assets/images/carousel-img/New folder/picture-on-road.webp";
import Img66 from "../../../assets/images/carousel-img/New folder/picture-on-greengrass.webp";
import Img67 from "../../../assets/images/carousel-img/New folder/pahalgm-2-person.webp";
import Img68 from "../../../assets/images/carousel-img/New folder/pahalgam-group-4.webp";
import Img69 from "../../../assets/images/carousel-img/New folder/pahalgam-3-person.webp";
import Img70 from "../../../assets/images/carousel-img/New folder/group-side-photo.webp";
import Img71 from "../../../assets/images/carousel-img/New folder/flag-picture-close.webp";
import Img72 from "../../../assets/images/carousel-img/New folder/flag-group-2.webp";
import Img73 from "../../../assets/images/carousel-img/New folder/bule-tree-back.webp";
import Img74 from "../../../assets/images/carousel-img/New folder/blue-raling-back.webp";









import Img46 from "../../../assets/images/carousel-img/New folder/img27.jpg";
import Img6 from "../../../assets/images/carousel-img/New folder/img28.webp";

import Img20 from "../../../assets/images/carousel-img/New folder/img31.jpg";
// import Img21 from "../../../assets/images/carousel-img/pic32.jpg";
import Img22 from "../../../assets/images/carousel-img/New folder/img32.jpg";
import Img23 from "../../../assets/images/carousel-img/New folder/img33.jpg";
import Img47 from "../../../assets/images/carousel-img/New folder/img26.jpg";




import Img30 from "../../../assets/images/carousel-img/New folder/img33.jpg";






import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.css"



//Define Default Content
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {/* Start Preloader Area */}
        <div className="preloader">
          <div className="loader loader1">
            <span style={{ "--i": 1 }} />
            <span style={{ "--i": 2 }} />
            <span style={{ "--i": 3 }} />
            <span style={{ "--i": 4 }} />
            <span style={{ "--i": 5 }} />
            <span style={{ "--i": 6 }} />
            <span style={{ "--i": 7 }} />
            <span style={{ "--i": 8 }} />
            <span style={{ "--i": 9 }} />
            <span style={{ "--i": 10 }} />
            <span style={{ "--i": 11 }} />
            <span style={{ "--i": 12 }} />
            <span style={{ "--i": 13 }} />
            <span style={{ "--i": 14 }} />
            <span style={{ "--i": 15 }} />
            <span style={{ "--i": 16 }} />
            <span style={{ "--i": 17 }} />
            <span style={{ "--i": 18 }} />
            <span style={{ "--i": 19 }} />
            <span style={{ "--i": 20 }} />
            <div className="rocket" />
          </div>
        </div>
        {/* End Preloader Area */}

        {/*---------Start Imported All Sections-----------*/}
        <MainBanner />

        <Packages />
        {/* <Destinations /> */}
        <div>
          <h2 style={{ textAlign: "center", marginTop: "10vw" }}><b>Don't miss out on our recent photos</b></h2>

          <div style={{ display: "flex" }} className=" Carousel col-lg-12 col-md-12 ">

            <div className="container">

              <div style={{ width: "90%", marginLeft: "3vw", }}
                className=" Carousel col-md-12 col-xs-12 col-lg-6  mt-50">

                <Carousel showThumbs={false} autoPlay infiniteLoop >


                  <div className="corouselImg">
                    <img src={Img16} />

                  </div>
                
                  
                 
                  <div className="corouselImg">
                    <img src={Img20} />

                  </div >
                  {/* <div>
                      <img src={Img21} />
                    
                    </div> */}
                  <div className="corouselImg">
                    <img src={Img22} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img23} />

                  </div>


                  <div className="corouselImg">
                    <img src={Img1} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img2} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img3} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img4} />

                  </div>
                
                  <div className="corouselImg">
                    <img src={Img6} />

                  </div>
                 
                  <div className="corouselImg">
                    <img src={Img8} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img9} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img10} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img11} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img12} />

                  </div>
                  {/* <div>
                      <img src={Img13} />
                     
                    </div> */}
                  <div className="corouselImg">
                    <img src={Img14} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img15} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img46} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img47} />

                  </div>
                 
                  <div className="corouselImg">
                    <img src={Img49} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img50} />

                  </div>
                  
                 
                  <div className="corouselImg">
                    <img src={Img25} />

                  </div>
               
                  <div className="corouselImg">
                    <img src={Img27} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img28} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img29} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img30} />

                  </div>
                
                  <div className="corouselImg">
                    <img src={Img32} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img33} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img34} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img35} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img36} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img61} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img62} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img63} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img64} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img65} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img66} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img67} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img68} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img69} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img70} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img71} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img72} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img73} />

                  </div>
                  <div className="corouselImg">
                    <img src={Img74} />

                  </div>
                
                 
                </Carousel>
              </div>

            </div>
            <div style={{ marginTop: "7vw" }} className="Carousel col-md-12 col-xs-12 col-lg-6 mt-50">
              <div>
                <p style={{ marginLeft: "3vw", marginRight: "3vw" }}>Our website's recent photographs section showcases the most recent images from our tours and events. From breathtaking landscapes to exciting group activities, this section is constantly updated with fresh content for our visitors to enjoy</p>
                <p style={{ marginLeft: "3vw", marginTop: "2vw", marginRight: "3vw" }}>We invite you to take a look and get a sense of the adventures we offer in KASHMIR. Whether you're planning a vacation or just looking for a little inspiration, we hope these photographs will give you a taste of what we have to offer at Ashir Holidays.</p>
              </div>
            </div>
          </div>
          <br/><br/>
          <Review />

        </div>
      </div>

    );
  }
}

export default HomePage;
